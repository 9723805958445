import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    avg: 0,
    currentPlatForm: 'AF',
    chargingData: {
      Id: null,
      Pw: null,
      platformId: null,
      platformPw: null,
      chargedCount: '',
      inflowPath: '추천인',
      discountRate: 0.03,
    },
    charedAllCount: 0,
    recommendTotalPoint: 0,
    serviceId: null,
    // name: null,
    role: null,
    token: null,
    recommenderList: [],
    chargeOnAir: [],
    getCoupons: [],
    salesItemList: [],
    errorCoupons: [],
    clientData: [],
    chargeData: {
      CP: 0,
      CL: 0,
      BL: 0,
      HM: 0,
      Data: []
    },
    recommendData: [],
    recommendDataTotal: {},
    yearSale: 0,
    MonthSale: 0,
    MonthSalePlatform: {
      AB: 0,
      CL: 0,
      BL: 0,
      HM: 0,
      FX: 0,
    },
    calendarMonth: {
      startMonth: '',
      endBasicMonth: '',
      endMonth: '',
    },
    chargedRecord: [],
    menuOn: true,
    menuList: [
      {
        path: '/layout',
        name: '충전',
      }
    ],
    retainedAmount: [],
  },
  getters: {
    avg: (state) => state.avg,
    retainedAmount: (state) => state.retainedAmount,
    menuList: (state) => state.menuList,
    menuOn: (state) => state.menuOn,
    currentPlatForm: (state) => state.currentPlatForm,
    chargingData: (state) => state.chargingData,
    chargedRecord: (state) => state.chargedRecord,
    charedAllCount: (state) => state.charedAllCount,
    recommendTotalPoint: (state) => state.recommendTotalPoint,
    role: (state) => state.role,
    recommenderList: (state) => state.recommenderList,
    serviceId: (state) => state.serviceId,
    recommendDataTotal: (state) => state.recommendDataTotal,
    recommendData: (state) => state.recommendData,
    MonthSalePlatform: (state) => state.MonthSalePlatform,
    MonthSale: (state) => state.MonthSale,
    endBasicMonth: (state) => state.calendarMonth.endBasicMonth,
    startMonth: (state) => state.calendarMonth.startMonth,
    endMonth: (state) => state.calendarMonth.endMonth,
    yearSale: (state) => state.yearSale,
    chargeData: (state) => state.chargeData,
    clientData: (state) => state.clientData,
    errorCoupons: (state) => state.errorCoupons,
    salesItems: (state) => state.salesItemList,
    getCoupons: (state) => state.getCoupons,
    chargeOnAir: (state) => state.chargeOnAir,
    isLogin(state) {
      return state.token !== null;
    },
    isSuper(state) {
      return state.role === "A";
    },
  },
  mutations: {
    setMenuList(state, role) {
      const superList = [{
          path: '/coupon-status',
          name: '쿠폰 현황',
        },
        {
          path: '/charging-status',
          name: '충전 현황',
        },
        {
          path: '/statistics',
          name: '통계관리',
        },
        {
          path: '/recommend-status',
          name: '추천인관리',
        }]
      if (role === '1') {
        state.menuList.push(...superList);
      }
      state.menuOn = false;
    },
    setCurrentPlatForm(state, res) {
      state.currentPlatForm = res;
    },
    setCalendarStartMonth(state, startMonth) {
      // console.log(startMonth);
      state.calendarMonth.startMonth = startMonth;
    },
    setCalendarEndBasicMonth(state, endMonth) {
      state.calendarMonth.endBasicMonth = endMonth;
    },
    setCalendarEndMonth(state, endMonth) {
      state.calendarMonth.endMonth = endMonth;
    },
    setMonthSale(state, res) {
      state.MonthSalePlatform = {
        AB: 0,
        CL: 0,
        BL: 0,
        HM: 0,
        FX: 0,
      };
      state.MonthSale = 0;
      res.forEach((item) => {
        state.MonthSale += Number(item?.complete_chared_count);
        state.MonthSalePlatform[item?.chargeType] += item?.complete_chared_count
      })
    },
    setYearSale(state, res) {
      state.yearSale = res;
    },
    setErrorCoupons(state, res) {
      state.errorCoupons = res;
    },
    setClientData(state, res) {
      state.chargingData.Id = res.Id;
      state.chargingData.Pw = res.Pw;
    },
    resetClientData(state) {
      state.chargingData = {
        Id: null,
        Pw: null,
        platformId: null,
        platformPw: null,
        chargedCount: '',
        inflowPath: '추천인',
        discountRate: 0.03,
      }
    },
    setRecommend(state, res) {
      state.recommendTotalPoint = 0; 

      state.recommendDataTotal = {};
      res.forEach((item) => {
        if (!state.recommendDataTotal[item.recommenderId]) {
          state.recommendDataTotal[item.recommenderId] = 0
        }
        if (item.type === 1 ) {
          item.type = '차감'
          item.point = parseFloat(item.point) * -1;
          state.recommendDataTotal[item.recommenderId] += parseFloat(item.point);
          state.recommendTotalPoint += parseFloat(item.point);
        } else {
          item.type = '적립'
          state.recommendDataTotal[item.recommenderId] += parseFloat(item.point);
          state.recommendTotalPoint += parseFloat(item.point);
        }
      })
      state.recommendDataTotal = Object.keys(state.recommendDataTotal).map(key => ({
        recommenderId: key,
        point: state.recommendDataTotal[key]
      }));
      state.recommendDataTotal.sort((a, b) => {
        const nameB = a.recommenderId.toUpperCase(); // 대소문자 구분 없이 정렬
        const nameA = b.recommenderId.toUpperCase(); // 대소문자 구분 없이 정렬

        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      })
      console.log(state.recommendDataTotal);
      state.recommendData = res;
    },
    setCoupons(state, res) {
      // const ERRORCODE = {
      //     '553': '이미 등록된 상품권입니다.',
      //     '555': '상품권 코드나 비밀번호가 일치하지 않습니다. 다시 시도해 주세요.',
      //     '551': '5회 이상 상품권 등록에 실패하여 상품권 등록이 불가합니다. 내일 다시 시도해 주세요.'
      // }
      // state.getCoupons = res.reverse().map((item, index) => {
      //   const newItem = {...item}; // Copy the original object
      //   newItem.index = index + 1; // Add the 'index' key
      //   return newItem;
      // });
      state.getCoupons = res.map((item, index) => {
        const newItem = {...item}; // Copy the original object
        newItem.index = index + 1; // Add the 'index' key
        return newItem;
      });

      state.chargeData.CP = state.getCoupons.reduce((prev, next) => {
        return prev + next.count;
      }, 0);
    },
    setPurchaseOnAir(state, res) {
      state.chargeData.CL = {};
      state.chargeData.BL = {};
      state.chargeData.HM = {};
      state.chargeData.Data = [];
      res.forEach((item) => {
        if (state.chargeData[item.chargeType][item.registId] === undefined) {
          state.chargeData[item.chargeType][item.registId] = 0
        }
        state.chargeData[item.chargeType][item.registId] += item.registPrice;
      });
      state.chargeData.Data = res;
    },
    setChargeOnAir(state, res) {
      state.charedAllCount = 0;
      state.chargeOnAir = res;
      const dataList = [];
      let salesItems = null;

      for (const i of res) {
        dataList.push(i);
      }


      salesItems = dataList.reverse();
      salesItems.forEach((e) => {
        state.charedAllCount += e.complete_chared_count;
        if (e.platform === 'AF') {
          if (e.chargeType === 'AB') {
            e.chargeType = '아프리카 교환권';
          } else if (e.chargeType === 'CL') {
            e.chargeType = '컬쳐랜드';
          } else if (e.chargeType === 'BL') {
            e.chargeType = '북앤라이프';
          } else if (e.chargeType === 'HM') {
            e.chargeType = '해피머니';
          }
        } else if (e.platform === 'FX') {
          if (e.chargeType === 'AB') {
            e.chargeType = '플렉스 교환권';
          }
        }
        if (e.success === 0) {
          e.success = '결제 실패'
        } else if (e.success === 1) {
          e.success = '결제 완료'
        } else if (e.success === 2) {
          e.success = '결제 진행중'
        }
        e.chared_count = Number(e.chared_count).toLocaleString();
        e.complete_chared_count = Number(e.complete_chared_count).toLocaleString();
        e.failed_count = Number(e.failed_count).toLocaleString();
      })

      state.salesItemList = salesItems;

      const clientData = {};
      state.salesItemList.forEach(item => {
        const id = item.afreecaId;
        if (!clientData[id]) {
          clientData[id] = [];
        }
        clientData[id].push(item);
      })

      let clientDataObj = {};

      clientDataObj = Object.entries(clientData).map(([key, value]) => {
        return {
          [key]: value
        };
      });

      let moreThanOnceSum = 0
      clientDataObj.forEach((item) => {
        for (const value in item){
          if (item[value].length >= 2) {
            moreThanOnceSum += 1;
          }
        }
      })
      state.avg = Math.round((moreThanOnceSum/clientDataObj.length) * 100) ? Math.round((moreThanOnceSum/clientDataObj.length) * 100) : 0;

      state.clientData = clientDataObj;
    },
    setToken(state, _token) {
      state.token = _token;
      localStorage.setItem("token", JSON.stringify(_token));
      axios.defaults.headers.common["Authorization"] = _token;
    },
    setServiceId(state, _serviceId) {
      localStorage.setItem('workerId', _serviceId);
      state.serviceId = _serviceId;
    },
    setRole(state, _role) {
      state.role = _role;
      // localStorage.setItem("role", JSON.stringify(_role));
    },
    setRecommender(state, res) {
      state.recommenderList = res;
    },
    // setName(state, _name) {
    //   state.name = _name;
    //   localStorage.setItem("name", JSON.stringify(_name));
    // },
    logout() {
      localStorage.clear();
      axios.defaults.headers.common["Authorization"] = undefined;
      location.replace("/login");
    },
    setChargedRecord(state, res) {
      state.chargedRecord = res;
    },
    setRetainedAmount(state, res) {
      state.retainedAmount = res;
    }
  },
  actions: {
    async getRetainedAmount({ commit }, data) {
      const { platForm } = data;
      const res = await axios.get(`${process.env.API_URL}/charge/retainedAmount?platForm=${platForm}`);
      commit('setRetainedAmount', res?.data);
    },
    async getChargingRecord({ commit }, data) {
      if (!data.AFID || !data.AFPW) return false;
      const res = await axios.get(`${process.env.API_URL}/charge/charging-record?id=${data.AFID}&pw=${data.AFPW}`);
      commit("setChargedRecord", res?.data);
    },
    // eslint-disable-next-line no-unused-vars
    async getLastRecommander({ commit }, data ) {
      return await axios.post(`${process.env.API_URL}/charge/last-recommender`, data);
    },
    // eslint-disable-next-line no-unused-vars
    async deduction({ commit }, data) {
      await axios.post(`${process.env.API_URL}/charge/deduction`, data)
    },
    async getUsers({ commit }, data) {
      try {
        const res = await axios.get(`${process.env.API_URL}/api/users/${data.userId}`);
        commit("setRole", res?.data?.T);
      } catch (err) {
        console.log('API오류', err);
      }
    },
    // eslint-disable-next-line no-unused-vars
    async reRegist({ commit }, data) {
      try {
        await axios.post(`${process.env.API_URL}/charge/serialReRegist`, data);
      } catch (err) {
        console.log('API오류', err);
      }
    },
    // eslint-disable-next-line no-unused-vars
    async getMonthSales({ commit }, data) {
      try {
        const res = await axios.post(`${process.env.API_URL}/charge/getMonthSales`, data);
        commit("setMonthSale", res.data);
      } catch (err) {
        console.log('API오류', err);
      }
    },
    // eslint-disable-next-line no-unused-vars
    async setStartMonth({ commit }, data) {
      try {
        commit("setCalendarStartMonth", data);
      } catch (err) {
        console.log('API오류', err);
      }
    },
    async setEndBasicMonth({ commit }, data) {
      try {
        commit("setCalendarEndBasicMonth", data);
      } catch (err) {
        console.log('API오류', err);
      }
    },
    async setEndMonth({ commit }, data) {
      try {
        commit("setCalendarEndMonth", data);
      } catch (err) {
        console.log('API오류', err);
      }
    },
    // eslint-disable-next-line no-unused-vars
    async getYearSales({ commit }, data) {
      try {
        const res = await axios.post(`${process.env.API_URL}/charge/getYearSales`, data);
        commit("setYearSale", res.data);
      } catch (err) {
        console.log('API오류', err);
      }
    },
    // eslint-disable-next-line no-unused-vars
    async deleteRecommender({ commit }, data) {
      try {
        await axios.delete(`${process.env.API_URL}/charge/recommender`, {data: data} );
      } catch (err) {
        console.log('API오류', err);
      }
    },
    // eslint-disable-next-line no-unused-vars
    async getRecommender({ commit }, data) {
      try {
        const { platForm } = data;
        const rs = await axios.get(`${process.env.API_URL}/charge/recommenders?platForm=${platForm}`);
        rs.data.sort((a, b) => {
          const nameA = a.name;
          const nameB = b.name;
        
          const isKoreanA = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(nameA);
          const isKoreanB = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(nameB);
        
          if (isKoreanA && !isKoreanB) {
            return -1;
          } else if (!isKoreanA && isKoreanB) {
            return 1;
          } else {
            return nameA.localeCompare(nameB, 'en', { sensitivity: 'base' });
          }
        });
        commit("setRecommender", rs.data);
      } catch (err) {
        console.log('API오류', err);
      }
    },
    // eslint-disable-next-line no-unused-vars
    async putRecommender({ commit }, data) {
      try {
        return await axios.post(`${process.env.API_URL}/charge/recommender`, data);
      } catch (err) {
        console.log('API오류', err);
      }
    },
    // eslint-disable-next-line no-unused-vars
    async errorCoupons({ commit }, data) {
      try {
        const res = await axios.post(`${process.env.API_URL}/charge/errorCoupon`, data);
        commit("setErrorCoupons", res.data);
      } catch (err) {
        console.log('API오류', err);
      }
    },
    // eslint-disable-next-line no-unused-vars
    async purchaseCharging({ commit }, data) {
      try {
        await axios.post(`${process.env.API_URL}/charge/purchaseCharging`, data);
      } catch (err) {
        console.log('API오류', err);
      }
    },
    // eslint-disable-next-line no-unused-vars
    async serachByDate({ commit }, data) {
      try {
        const { startDate, endDate, parameter, platform } = data;
        if (parameter.includes('recommend-status')) {
          this.dispatch('getRecommend', { startDate, endDate, platForm: platform });
        } else if (parameter.includes('coupon-status')) {
          this.dispatch('getCoupons', { startDate, endDate, platform });
        } else if (parameter.includes('charging-status')) {
          this.dispatch('getChargeOnAir', { startDate, endDate, platForm: platform });
        }
        // await axios.post(`${process.env.API_URL}/charge/AfreecaCharge`, data);
      } catch (err) {
        console.log('API오류', err);
      }
    },
    // eslint-disable-next-line no-unused-vars
    async charging({ commit }, data) {
      try {
        await axios.post(`${process.env.API_URL}/charge/TvCharge`, data);
      } catch (err) {
        console.log('API오류', err);
      }
    },
    async getRecommend ({ commit }, data) {
      try {
        const startDate = data?.startDate;
        const endDate = data?.endDate;
        const platForm = data?.platForm;
        const res = await axios.get(
          `${process.env.API_URL}/charge/recommendList?startDate=${startDate}&endDate=${endDate}&platForm=${platForm}`
        );
        commit("setRecommend", res.data);
      } catch(err) {
        console.log('API오류', err);
      }
    },
    async getCoupons ({ commit }, data) {
      try {
        const platform = data?.platform;
        const startDate = data?.startDate;
        const endDate = data?.endDate;
        const res = await axios.get(
          `${process.env.API_URL}/charge/couponList?startDate=${startDate}&endDate=${endDate}&platform=${platform}`
        );
        commit("setCoupons", res.data);
      } catch(err) {
        console.log('API오류', err);
      }
    },
    async getPurchaseCharging ({ commit }) {
      try {
        const res = await axios.get(
          `${process.env.API_URL}/charge/getPurchaseCharging`
        );
        commit('setPurchaseOnAir', res.data);
      }
      catch (err) {
        console.log('API오류', err);
      }
    },
    async getChargeOnAir ({ commit }, data) {
      try {
        const startDate = data?.startDate;
        const endDate = data?.endDate;
        const platForm = data?.platForm;
        const limit = window.location.pathname.includes('layout') ? 10 : undefined;
        const res = await axios.get(
          `${process.env.API_URL}/charge/chargeOnAir?startDate=${startDate}&endDate=${endDate}&platForm=${platForm}&limit=${limit}`
        );
        commit('setChargeOnAir', res.data);
      }
      catch (err) {
        console.log('API오류', err);
      }
    },
    setToken: ({ commit }, _token) => {
      commit("setToken", _token);
    },
    setServiceId: ({ commit }, _serviceId) => {
      commit("setServiceId", _serviceId);
    },
    setRole: ({ commit }, _role) => {
      commit("setRole", _role);
    },
    // setName: ({ commit }, _name) => {
    //   commit("setName", _name);
    // },
    logout: ({ commit }) => {
      commit("logout");
    },
  },
  modules: {},
});
