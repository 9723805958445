<template>
  <b-container>
    <Header></Header>
    <recommendTable></recommendTable>
  </b-container>
</template>

<script>
import {mapGetters} from "vuex";
import recommendTable from "@/components/recommendTable.vue";
import Header from "@/components/Nav/Header.vue";

export default {
  name: "recommendStatus",
  components: {Header, recommendTable},
  computed: {
    ...mapGetters({
      role: (['role']),
    })
  },
  async created() {
    this.currentPath = window.location.pathname;
    await this.$store.dispatch('getUsers', {userId: localStorage.getItem('workerId')});
    if (this.role !== '1') {
      this.$router.push('/layout');
    }
  },
}
</script>

<style scoped>

</style>