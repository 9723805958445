<template>
  <div style="margin-bottom: 50px;">
    <b-tabs content-class="p-4">
      <b-tab title="추천인 현황" active>
        <div class="d-flex"><h2 class="fw-900 m-0 mb-3">추천인 현황</h2></div>
        <div class="justify-content-between align-items-center mb-3 d-flex flex-wrap">
          <b-row class="justify-content-end align-items-center">
            <!-- 달력 검색 -->
            <b-col>
              <Calendar></Calendar>
            </b-col>
            <!-- 검색어 -->
            <b-col>
              <b-form-group>
                <b-input-group>
                  <b-form-input
                          id="filter-input"
                          v-model="filter"
                          type="search"
                          placeholder="검색어"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-btn
                            @click="filter = null"
                            :disabled="!filter"
                            variant="outline-dark"
                            style="
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                  "
                    >초기화
                    </b-btn
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <span>전체 포인트 : {{ recommendTotalPoint.toLocaleString() }}원</span>
        <b-table
                head-variant="dark"
                bordered
                responsive
                hover
                selectable
                select-mode="single"
                @row-selected="onRowSelected"
                :sticky-header="true"
                :items="recommendData"
                :fields="fields"
                class="dataTable salesTable chargingTable"
                :filter="filter"
                ref="selectableTable"
                show-empty
                emptyFilteredText="찾으시는 검색어와 일치하는 정보가 없습니다."
                emptyText="데이터 정보가 없습니다."
        >
          <template #head()="data">
            <div @click="isEllipsis = !isEllipsis" class="cursor-pointer">
              {{ data.label }}
            </div>
          </template>
          <template #cell()="data">
        <span>
          {{ data.value ? data.value : empty }}
        </span>
          </template>

          <!-- No -->
          <template #cell(index)="row">
            {{ row.index + 1 }}
          </template>
          <!-- 계약번호 -->
          <template #cell(ContractNumber)="row">
        <span>
          {{ dateFormat1(row.item.ContractNumber) }}
        </span>
          </template>
          <!-- 실시간트래픽 -->
          <template #cell(trafficDataTodayCount)="row">
        <span>
          {{
            row.item.trafficData
                ? isNegative(row.item.trafficData.today_remain_count)
                : empty
          }}
        </span>
          </template>
          <template #cell(point)="row">
            <span>
              {{ parseFloat(row.item.point).toLocaleString() }}원
            </span>
          </template>
          <!-- 디자인 -->
          <template #cell(Design)="row">
            <div class="text-center">
              <font-awesome-icon
                      v-if="row.item.Design"
                      class="fa-xl text-success"
                      icon="fa-solid fa-circle-check"
              />
              <font-awesome-icon
                      v-else
                      style="color: #ced4da"
                      class="fa-xl"
                      icon="fa-solid fa-circle-xmark"
              />
            </div>
          </template>
          <!-- 트래픽 -->
          <template #cell(Traffic)="row">
            <div class="text-center">
              <font-awesome-icon
                      v-if="row.item.Traffic"
                      class="fa-xl text-success"
                      icon="fa-solid fa-circle-check"
              />
              <font-awesome-icon
                      v-else
                      style="color: #ced4da"
                      class="fa-xl"
                      icon="fa-solid fa-circle-xmark"
              />
            </div>
          </template>
          <!-- 확인 -->
          <template #cell(Approved)="row">
            <div class="text-center">
              <font-awesome-icon
                      v-if="row.item.Approved"
                      class="fa-xl text-success"
                      icon="fa-solid fa-circle-check"
              />
              <font-awesome-icon
                      v-else
                      style="color: #ced4da"
                      class="fa-xl"
                      icon="fa-solid fa-circle-xmark"
              />
            </div>
          </template>
        </b-table>
      </b-tab>
      <b-tab title="추천인 관리">
        <div style="display: inline-flex">
          <h2 class="rw-900 m-0 mb-3">추천인 관리</h2>
        </div>
        <div class="justify-content-between align-items-center mb-3 d-flex">
          <b-table-simple small bordered fixed class="currentTable">
            <b-tbody>
              <b-tr>
                <b-th>추천인 ID</b-th>
                <b-td>
                  <b-form-input
                          v-model="selectedData.recommendId"
                  ></b-form-input>
                </b-td>
                <b-th>차감 포인트</b-th>
                <b-td>
                  <b-form-input
                          v-model="selectedData.deductionPoints"
                          @input="formatChargedPrice"
                  ></b-form-input>
                </b-td>
              </b-tr>
              <!-- 계좌이체 -->
            </b-tbody>
          </b-table-simple>
          <b-row class="">
            <b-col class="text-end">
              <b-btn variant="dark" class="m-2 " @click="deduction()">차감</b-btn>
            </b-col>
          </b-row>
        </div>
        <div class="justify-content-between align-items-center mb-3 d-flex flex-wrap">
          <b-row class="justify-content-end align-items-center">
            <!-- 달력 검색 -->
            <b-col>
              <Calendar></Calendar>
            </b-col>
            <!-- 검색어 -->
            <b-col>
              <b-form-group v-slot="{ ariaDescribedby }">
                <b-input-group>
                  <b-form-input
                          id="filter-input"
                          v-model="filter"
                          :aria-describedby="ariaDescribedby"
                          type="search"
                          placeholder="검색어"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-btn
                            @click="filter = null"
                            :disabled="!filter"
                            variant="outline-dark"
                            style="
                      border-top-left-radius: 0;
                      border-bottom-left-radius: 0;
                    "
                    >초기화</b-btn
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <span>전체 포인트 : {{ recommendTotalPoint.toLocaleString() }}원</span>
        <b-table
                head-variant="dark"
                bordered
                responsive
                hover
                selectable
                select-mode="single"
                @row-selected="recommendSelected"
                :sticky-header="true"
                :items="recommendDataTotal"
                :fields="clientFields"
                class="dataTable salesTable chargingTable"
                :filter="filter"
                ref="selectableTable"
                show-empty
                emptyFilteredText="찾으시는 검색어와 일치하는 정보가 없습니다."
                emptyText="데이터 정보가 없습니다."
        >
          <template #head()="data">
            <div @click="isEllipsis = !isEllipsis" class="cursor-pointer">
              {{ data.label }}
            </div>
          </template>
          <template #cell()="data">
        <span>
          {{ data.value ? data.value : empty }}
        </span>
          </template>
          <template #cell(point)="row">
            <span>
              {{ row.item.point.toLocaleString() }}원
            </span>
          </template>

          <!-- No -->
          <template #cell(index)="row">
            {{ row.index + 1 }}
          </template>
        </b-table>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
// import { MonthPicker } from "vue-month-picker";
import {mapGetters} from "vuex";
// import VDatePicker from "v-calendar/lib/components/date-picker.umd";
import Calendar from "@/components/Calendar.vue";

export default {
  name: "recommendTable",
  components: { Calendar },
  props: {
    limit: {
      type: Number,
    }
  },
  computed: {
    ...mapGetters({
      currentPlatForm: (['currentPlatForm']),
      recommendData: (['recommendData']),
      recommendDataTotal: (['recommendDataTotal']),
      recommendTotalPoint: (['recommendTotalPoint']),
    }),
  },
  data: () => ({
    avg: 0,
    inflowDataList: [],
    selectedData: {
      recommendId: '',
      deductionPoints: 0,
    },
    flowModal: false,
    onModal: false,
    isEllipsis: false,
    // selectedDate: null,
    monthPickerVisible: false,
    filter: "",
    addTag: false,
    currentData: {},
    cachedData: {},
    paymentType: "AB",
    managerPricePredicted: null,
    managerPriceConfirm: null,
    salesItems: null,
    totalPricePredicted: null,
    totalPriceConfirm: null,
    fields: [
      {
        key: "index",
        label: "No",
        variant: "secondary",
        thClass: "",
      },
      {
        key: "recommenderId",
        label: "추천인 ID",
        thClass: "table-secondary",
      },
      {
        key: "chargedId",
        label: "아프리카 ID",
        thClass: "table-secondary",
      },
      {
        key: "point",
        label: "포인트",
        thClass: "table-secondary",
      },
      {
        key: "type",
        label: "상태",
        thClass: "table-secondary",
      },
      {
        key: "create_time",
        label: "날짜",
        thClass: "table-secondary",
      },
    ],
    clientFields: [
      {
        key: "index",
        label: "No",
        // isRowHeader: true,
        // sortable: true,
        variant: "secondary",
        thClass: "",
        // filterByFormatted: true,
      },
      {
        key: "recommenderId",
        label: "추천인 ID",
        thClass: "table-secondary",
      },
      {
        key: "point",
        label: "보유포인트",
        thClass: "table-secondary",
      },
    ],
    empty: "",
  }),
  async created() {
    await this.getRecommend();
  },
  methods: {
    async getRecommend() {
      await this.$store.dispatch('getRecommend', { platForm: this.currentPlatForm });
    },
    async deduction() {
      this.selectedData.platForm = this.currentPlatForm;
      await this.$store.dispatch('deduction', this.selectedData);
      await this.getRecommend();
    },
    async formatChargedPrice() {
      if (!this.selectedData.deductionPoints) return; // 입력값이 없을 경우 빠른 리턴

      const priceValue = parseFloat(this.selectedData.deductionPoints.replace(/,/g, ''));
      if (!isNaN(priceValue)) {
        this.selectedData.deductionPoints = priceValue.toLocaleString();
      }
    },
    getInflowPath(row) {
      this.flowModal = true;
      const inflowData = {};
      row.forEach(item => {
        const path = item.inflowPath;
        if (!inflowData[path]) {
          inflowData[path] = 1;
        } else {
          inflowData[path] += 1;
        }
      })
      this.inflowDataList = inflowData;
    },
    recommendSelected(items) {
      this.selectedData.recommendId = items[0]?.recommenderId;
    },
    onRowSelected(items) {
      // console.log(items);
    },
    // showMonthPicker() {
    //   this.monthPickerVisible = !this.monthPickerVisible;
    // },
    async showDate() {
      const data = await this.$axios.get(
        `${process.env.API_URL}/charge/chargeOnAir`
      );

      this.onMonthsalesData(data);
    },
    onMonthsalesData(data) {
      this.onList(data);
    },
  },
}
</script>

<style scoped>

</style>