<template>
  <div class="remodal">
    <!-- 모달 내용 -->
    <div class="remodal-content">
        <!-- 모달 내용의 내용 -->
      <div>상품권 번호 : {{this.reItem?.serialNumber}}</div>
      <div>상품권 가격 : {{this.reItem?.price}}</div>
      <div style="margin-top: 10px; display: grid;">
        <b-input placeholder="상품권 번호" style="margin-top: 5px;" v-model="serialNum"></b-input>
        <b-input placeholder="상품권 가격" style="margin-top: 5px;" v-model="price" @input="formatChargedPrice"></b-input>
        <b-input placeholder="상품권 비밀번호" style="margin-top: 5px;" v-model="password"></b-input>
        <b-button @click="reRegist" style="margin-top: 5px;">재등록</b-button>
        <b-button @click="closeModal" style="margin-top: 5px;">닫기</b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "reRegist",
  props: {
    reItem : Array
  },
  data: () => ({
    serialNum: '',
    price: '',
    password: '',
  }),
  mounted() {
    // console.log(this.reItem);
  },
  methods: {
    async formatChargedPrice() {
      this.price = this.price.replaceAll(',', '')
      if (!this.price) return; // 입력값이 없을 경우 빠른 리턴

      this.price = Number(this.price).toLocaleString();
    },
    async reRegist() {
      await this.$store.dispatch('reRegist', {id: this.reItem.id, serialNum: this.serialNum, price: this.price, password: this.password})
      location.reload();
    },
    closeModal() {
      this.$emit("close"); // 이벤트를 발생시켜 부모 컴포넌트에 모달을 닫도록 알립니다.
    }
  }
}
</script>

<style lang="scss">
</style>
