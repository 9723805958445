<template>
  <b-form-group>
    <b-input-group class="position-relative">
      <b-form-input
              v-model="startDate"
              class="form-control p-2"
              type="text"
              placeholder="시작날짜"
              readonly
              @click="showStartDateCalendar"
      ></b-form-input>
      <VDatePicker
              locale="ko"
              title-position="left"
              color="gray"
              v-model="selectedStartDate"
              @input="onStartDateSelected"
              is-required
              class="position-absolute"
              style="z-index: 10; top: 3rem"
              v-if="showStartDateCalendarPopup"
      ></VDatePicker>
      <b-form-input
              v-model="endDate"
              class="form-control p-2"
              type="text"
              placeholder="끝날짜"
              readonly
              @click="showEndDateCalendar"
      ></b-form-input>
      <VDatePicker
              locale="ko"
              title-position="left"
              color="gray"
              v-model="selectedEndDate"
              @input="onEndDateSelected"
              is-required
              class="position-absolute"
              style="z-index: 10; top: 3rem"
              v-if="showEndDateCalendarPopup"
      ></VDatePicker>
      <b-input-group-append>
        <b-btn
                @click="searchDate()"
                variant="outline-dark"
                style="border-radius: 0"
                class="p-2"
        >조회
        </b-btn>
      </b-input-group-append>
      <b-input-group-append>
        <b-btn
                @click="clearDate()"
                variant="outline-dark"
                style="border-radius: 0"
                class="p-2"
        >초기화
        </b-btn>
      </b-input-group-append>
    </b-input-group>
  </b-form-group>
</template>

<script>
import VDatePicker from "v-calendar/lib/components/date-picker.umd";
import {mapGetters} from "vuex";

export default {
  name: "Calendar",
  components: {VDatePicker},
  data: () => ({
    parameter: window.location.pathname,
    startDate: '',
    endDate: '',
    selectedStartDate: null,
    selectedEndDate: null,
    showStartDateCalendarPopup: false,
    showEndDateCalendarPopup: false,
  }),
  computed: {
    ...mapGetters({
      currentPlatForm: (['currentPlatForm']),
    }),
  },
  mounted() {
    this.startDate = this.formatDate(new Date());
    this.endDate = this.formatDate(new Date());
  },
  methods: {
    showStartDateCalendar() {
      this.showStartDateCalendarPopup = !this.showStartDateCalendarPopup;
      this.showEndDateCalendarPopup = false;
    },
    showEndDateCalendar() {
      this.showEndDateCalendarPopup = !this.showEndDateCalendarPopup;
      this.showStartDateCalendarPopup = false;
    },
    onStartDateSelected(date) {
      this.selectedStartDate = new Date(date);
      this.startDate = this.formatDate(this.selectedStartDate);
      this.showStartDateCalendarPopup = false;
    },
    onEndDateSelected(date) {
      console.log(date);
      this.selectedEndDate = new Date(date);
      this.endDate = this.formatDate(this.selectedEndDate);
      this.showEndDateCalendarPopup = false;
    },
    formatDate(date) {
      // 날짜 형식을 원하는 형태로 변환하는 함수를 정의합니다.
      // 예: YYYY-MM-DD 형태로 변환
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    async searchDate() {
      if (!this.startDate || !this.endDate) return false;
      await this.searchDispatch();
    },
    async searchDispatch() {
      await this.$store.dispatch("serachByDate", {
        parameter: this.parameter,
        startDate: this.startDate,
        endDate: this.endDate,
        platform: this.currentPlatForm,
      })
    },
    async clearDate() {
      this.startDate = undefined;
      this.endDate = undefined;
      this.selectedStartDate = null,
      this.selectedEndDate = null,
      await this.searchDispatch();
    },
  },
}
</script>

<style scoped>

</style>