<template>
  <b-row class="mb-4">
    <b-col v-if="currentPlatForm === 'AF'">
      <b-form-select v-model="chargePrice" class="mb-3">
        <b-form-select-option value="0">금액설정</b-form-select-option>
        <b-form-select-option value="500">500</b-form-select-option>
        <b-form-select-option value="1000">1,000</b-form-select-option>
      </b-form-select>
      <b-form-textarea class="mb-3" v-model="couponDatas" @input="changeRegistCount" placeholder="ABCDEFG123456-23456
ABCDEFG123456-23456
ABCDEFG123456-23456
ABCDEFG123456-23456"></b-form-textarea>
      <b-row class="justify-content-between align-items-center">
        <b-col class="text-end">
          <span style="float: left;">{{registCnt}}개 등록</span>
          <b-btn variant="dark" class="ms-2" @click="addData()">
            등록완료
          </b-btn>
        </b-col>
      </b-row>
    </b-col>
    <b-col v-else-if="currentPlatForm === 'FX'">
      <b-form-select v-model="chargePrice" class="mb-3">
        <b-form-select-option value="0">금액설정</b-form-select-option>
        <b-form-select-option value="500">500</b-form-select-option>
        <b-form-select-option value="1000">1,000</b-form-select-option>
      </b-form-select>
      <b-form-textarea class="mb-3" v-model="couponDatas" @input="changeRegistCount" placeholder="ABCDEFG123456 23456
ABCDEFG123456 23456
ABCDEFG123456 23456
ABCDEFG123456 23456"></b-form-textarea>
      <b-row class="justify-content-between align-items-center">
        <b-col class="text-end">
          <span style="float: left;">{{registCnt}}개 등록</span>
          <b-btn variant="dark" class="ms-2" @click="addData()">
            등록완료
          </b-btn>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import {mapGetters} from "vuex";

export default {
  name: "Regist",
  data: () => ({
    registCnt: 0,
    chargePrice: 0,
    couponDatas: '',
  }),
  computed: {
    ...mapGetters({
      currentPlatForm: (['currentPlatForm']),
    })
  },
  methods: {
    async changeRegistCount() {
      if (this.couponDatas === '') {
        this.registCnt = 0;
      } else {
        this.registCnt = this.couponDatas.split('\n').length;
      }
    },
    async downloadTextFile(text, filename) {
      const blob = new Blob([text], { type: 'text/plain' });
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = filename;
      a.click();
      URL.revokeObjectURL(a.href);
    },
    async resetData() {
      await this.$store.dispatch('getCoupons', { platform: this.currentPlatForm });
      await this.$store.dispatch('getRetainedAmount', { platForm: this.currentPlatForm });
      this.registCnt = 0;
      this.chargePrice = 0;
      this.couponDatas = '';
    },
    // 신규등록 완료
    async addData() {
      if (Number(this.chargePrice) === 0) {
        await Swal.fire({
          title: '쿠폰 금액',
          text: `쿠폰 금액을 설정해주세요.`,
          icon: 'error',
        })
      } else {
        if (this.couponDatas === '') {
          await Swal.fire({
            title: '쿠폰',
            text: `쿠폰 번호를 입력해주세요.`,
            icon: 'error',
          })
        } else {
          let couponDatas = this.couponDatas.split('\n');
          if (this.currentPlatForm === 'AF') {
            couponDatas = couponDatas.map((item) => item.replaceAll(' ','').replaceAll('\t',''));
          }
          const couponList = `${couponDatas.length}개 충전이 맞습니까?`;
          await Swal.fire({
            title: '충전 내용',
            html: couponList,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: '네',
            cancelButtonText: '아니오',
          }).then(async (result) => {
            if (result.isConfirmed) {
              // // eslint-disable-next-line no-unused-vars
              const registReturn = await axios.post(`${process.env.API_URL}/charge/serialRegist`, {
                platform: this.currentPlatForm,
                chargePrice: this.chargePrice,
                couponDatas,
              });
              if (registReturn.data.length > 0) {
                let failCoupons = registReturn.data.map((item, idx) => `${idx + 1}. ${item}`)
                let failCoupon;
                failCoupon = failCoupons.join('<br>');
                await Swal.fire({
                  title: '실패 쿠폰',
                  html: failCoupon,
                  icon: 'question',
                  showCancelButton: true,
                  confirmButtonText: '다운로드',
                  cancelButtonText: '닫기',
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    // 확인 버튼을 클릭한 경우 실행할 함수
                    const ErrorCoupon = failCoupons.join('\n');
                    await this.downloadTextFile(ErrorCoupon, 'errorCoupon');
                  }
                });
              } else {
                await Swal.fire({
                  title: '쿠폰',
                  text: `쿠폰 등록이 완료 되었습니다.`,
                  icon: 'success',
                });
              }
            }
          })
          await this.resetData();
        }
      }

    },
  },
}
</script>

<style scoped>

</style>