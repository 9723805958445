<template>
  <div class="mb-4" style="border: solid 3px #dee2e6; padding: 20px; border-radius: 10px; box-shadow: 0px 0px 5px 3px;">
    <b-col>
      <b-table-simple small bordered fixed class="currentTable">
        <b-tbody>
          <b-tr>
            <b-th>상품권 종류</b-th>
            <b-td>
              <b-form-select v-model="paymentType">
                <template v-if="currentPlatForm === 'AF'">
                  <b-form-select-option value="AB">아프리카 교환권</b-form-select-option>
                  <b-form-select-option value="BL">북앤라이프</b-form-select-option>
                  <b-form-select-option value="CL">컬쳐랜드</b-form-select-option>
                  <b-form-select-option value="HM">해피머니</b-form-select-option>
                </template>
                <template v-else-if="currentPlatForm === 'FX'">
                  <b-form-select-option value="AB">렉스 교환권</b-form-select-option>
                </template>
              </b-form-select>
            </b-td>
            <b-th>고객 아이디</b-th>
            <b-td>
              <b-form-input
                      v-model="chargingData.Id"
              @input="changeAfId()"></b-form-input>
            </b-td>
            <b-th>고객 비밀번호</b-th>
            <b-td>
              <b-form-input v-model="chargingData.Pw"></b-form-input>
            </b-td>
          </b-tr>
          <b-tr v-if="currentPlatForm === 'AF'">
            <b-th>상품권 아이디</b-th>
            <b-td>
              <template v-if="paymentType === 'AB'">
                <b-form-input v-if="isEmpty(currentData)" disabled :value="empty"></b-form-input>
                <b-form-input v-else :disabled="!updateTag" v-model="chargingData.platformId"></b-form-input>
              </template>
              <template v-else>
                <b-form-input v-model="chargingData.platformId"></b-form-input>
              </template
              >
            </b-td>
            <b-th>상품권 비밀번호</b-th>
            <b-td>
              <template v-if="paymentType === 'AB'">
                <b-form-input v-if="isEmpty(currentData)" disabled :value="empty"></b-form-input>
                <b-form-input v-else :disabled="!updateTag" v-model="chargingData.platformPw"></b-form-input>
              </template>
              <template v-else>
                <b-form-input v-model="chargingData.platformPw"></b-form-input>
              </template
              >
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>충전 개수</b-th>
            <b-td>
              <b-form-input v-model="chargingData.chargedCount" @input="formatChargedPrice"
              ></b-form-input>
            </b-td>
            <b-td>
              <b-card-text style="text-align: right;">{{chargedPrice}}원</b-card-text>
            </b-td>
            <b-td>
              <b-button @click="changeDiscountRate(0.03)" style="height: 30px; font-size: small; margin-right: 3px;" v-if="!discountOn">3%</b-button>
              <b-button @click="changeDiscountRate(0.04)" style="height: 30px; font-size: small; margin-right: 3px;" v-if="!discountOn">4%</b-button>
              <b-button @click="changeDiscountRate(0.06)" style="height: 30px; font-size: small; margin-right: 3px;" v-if="!discountOn">6%</b-button>
              <b-button @click="changeDiscountInput()" style="height: 30px; font-size: small;" v-if="!discountOn">기타</b-button>
              <div v-if="discountOn" style="display: flex;">
                <b-input @input="chageInputDiscount()" v-model="inputDiscountRate" style="text-align: center;" placeholder="0%"></b-input>
                <b-button @click="changeDiscountInput()" style="height: 30px; font-size: small; width: 100px; margin-left: 10px;">취소</b-button>
              </div>
            </b-td>
            <b-th>추천인</b-th>
            <b-td>
              <div style="display: flex; align-items: center;">
                <b-form-select v-model="inflowPath1" v-if="!is_recommender" style="width: 50%;">
                  <b-form-select-option value="추천인">추천인</b-form-select-option>
                  <template v-for="(key) in recommenderList">
                    <b-form-select-option :value="key.name" :key="key.name">{{ key.name }}</b-form-select-option>
                  </template>
                </b-form-select>
                <b-form-select v-model="inflowPath2" v-if="!is_recommender" style="width: 50%;">
                  <b-form-select-option value="추천인">추천인</b-form-select-option>
                  <template v-for="(key) in recommenderList">
                    <b-form-select-option :value="key.name" :key="key.name">{{ key.name }}</b-form-select-option>
                  </template>
                </b-form-select>
                <b-input v-if="is_recommender" v-model="recommenderName"></b-input>
                <button style="width: 80px; margin: 0 3px 0; border-radius: 10px" @click="recommenderOn" v-if="!is_recommender">추가</button>
                <button style="width: 200px; margin: 0 3px 0; border-radius: 10px" @click="putRecommender" v-if="is_recommender">추천인 추가</button>
                <button style="width: 80px; margin: 0 3px 0; border-radius: 10px" @click="notRecommender" v-if="is_recommender">취소</button>
                <button style="width: 80px; margin: 0 3px 0; border-radius: 10px" v-if="!is_recommender" @click="deleteRecommender">삭제</button>
              </div>
            </b-td>
          </b-tr>
          <!-- 계좌이체 -->
        </b-tbody>
      </b-table-simple>
      <b-row class="justify-content-between align-items-center">
        <b-btn variant="dark" class="px-2" @click="chaging()">충전하기</b-btn>
      </b-row>
    </b-col>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import {mapGetters} from "vuex";

export default {
  name: "Charge",
  data: () => ({
    paymentType: 'AB',
    is_recommender: false,
    recommenderName: '',
    inputDiscountRate: null,
    chargedPrice: 0,
    currentData: {},
    updateTag: false,
    empty: "",
    numStrPrice: "",
    timeoutId: null,
    discountOn: false,
    inflowPath1: '추천인',
    inflowPath2: '추천인',
  }),
  computed: {
    ...mapGetters({
      currentPlatForm: (['currentPlatForm']),
      chargingData: (['chargingData']),
      serviceId: (['serviceId']),
      recommenderList: (['recommenderList']),
    })
  },
  async mounted() {
    await this.getRecommender();
  },
  methods: {
    async chageInputDiscount() {
      this.inputDiscountRate = this.inputDiscountRate.replaceAll('%', '');
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(async () => {
        this.changeDiscountRate(Number((this.inputDiscountRate).replaceAll('%', '')) / 100);
      }, 300);
      this.inputDiscountRate = `${this.inputDiscountRate}%`;
    },
    async changeDiscountInput() {
      this.discountOn = !this.discountOn;
    },
    async changeDiscountRate(rate) {
      this.chargingData.discountRate = rate;
      await this.formatChargedPrice();
    },
    async changeAfId() {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(async () => {
        const result = await this.$store.dispatch('getLastRecommander', {afId: this.chargingData.Id});
        if (result.data.length <= 0) return this.chargingData.inflowPath = '추천인';
        this.chargingData.inflowPath = result.data[0].inflowPath;
      }, 1000);
    },
    async deleteRecommender() {
      await this.$store.dispatch('deleteRecommender', {recommender : this.chargingData.inflowPath, platForm: this.currentPlatForm});
      await this.getRecommender()
    },
    async putRecommender() {
      await this.$store.dispatch('putRecommender', {name : this.recommenderName, platForm: this.currentPlatForm});
      await this.recommenderOn();
      await this.getRecommender()
    },
    async notRecommender() {
      this.is_recommender = !this.is_recommender;
    },
    async recommenderOn() {
      this.is_recommender = !this.is_recommender;
    },
    async getRecommender() {
      await this.$store.dispatch('getRecommender', { platForm: this.currentPlatForm });
      this.chargingData.inflowPath = '추천인';
    },
    async formatChargedPrice() {
      if (this.chargingData.chargedCount === '') {
        this.chargingData.chargedCount = '';
        this.chargedPrice = 0;
        return false;
      }
      const chargeInt = parseInt(this.chargingData.chargedCount.replaceAll(',', ''));
      this.chargingData.chargedCount = chargeInt.toLocaleString();

      this.chargedPrice = (chargeInt * 110 - (chargeInt * 110 * this.chargingData.discountRate)).toLocaleString();
    },
    async inflowPathValid() {
      const inflowPathList = []; // 추천인 빈배열
      let inflowPath1 = this.inflowPath1 === '추천인' || this.inflowPath1 === '' ? '' : this.inflowPath1.split('-');
      let inflowPath2 = this.inflowPath2 === '추천인' || this.inflowPath2 === '' ? '' : this.inflowPath2.split('-');
      if (inflowPath1 !== '') inflowPath1 = inflowPathList.push(inflowPath1[inflowPath1.length - 1]); // 추천인 존재 할경우 배열에 추가
      if (inflowPath2 !== '') inflowPath2 = inflowPathList.push(inflowPath2[inflowPath2.length - 1]); // 추천인 존재 할경우 배열에 추가
      this.chargingData.inflowPath = inflowPathList.join(',') // 추천인 ','로 묶어서 문자열로 변경
    },
    async chaging() {
      await this.inflowPathValid();
      this.chargingData.inflowPath = this.chargingData.inflowPath.name !== undefined ? this.chargingData.inflowPath.name : this.chargingData.inflowPath;
      Swal.fire({
        title: '충전하기',
        text: `충전 개수가 ${this.chargingData.chargedCount ? this.chargingData.chargedCount : 0}개 맞습니까?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: '네',
        cancelButtonText: '아니오',
      }).then(async result => {

        if (result.isConfirmed) {
          await this.$store.dispatch('charging', {
            platform: this.currentPlatForm,
            card: this.paymentType,
            tvId: this.chargingData.Id,
            tvPw: this.chargingData.Pw,
            Id: this.chargingData.platformId,
            Pw: this.chargingData.platformPw,
            count: this.chargingData.chargedCount,
            inflowPath: this.chargingData.inflowPath,
            discountRate: this.chargingData.discountRate,
            workId: localStorage.getItem('workerId'),
          });
          this.$store.commit('resetClientData');
          this.chargedPrice = 0;
          await this.$store.dispatch('getCoupons', { platform: this.currentPlatForm});
        }
      })
    },
  },
}
</script>

<style scoped>

</style>