<template>
  <b-form-group>
    <b-input-group class="position-relative">
      <b-form-input
              v-model="startMonth"
              class="form-control p-2"
              type="text"
              placeholder="시작 달"
              readonly
              @click="showStartMonthCalendar"
      ></b-form-input>
      <month-picker
                  class="position-absolute"
                  style="z-index: 10; top: 3rem"
                  lang="ko"
                  editable-year
                  date-format="%Y년 %n"
                  no-default
                  v-model="selectedStartMonth"
                  v-if="showStartMonthCalendarPopup"
                  @change="monthStartOn"
                ></month-picker>
      <b-form-input
              v-model="endMonth"
              class="form-control p-2"
              type="text"
              placeholder="마지막 달"
              readonly
              @click="showEndMonthCalendar"
      ></b-form-input>
      <month-picker
                  class="position-absolute"
                  style="z-index: 10; top: 3rem; left: 27rem"
                  lang="ko"
                  editable-year
                  date-format="%Y년 %n"
                  no-default
                  v-model="selectedEndMonth"
                  v-if="showEndMonthCalendarPopup"
                  @change="monthEndOn"
                ></month-picker>
    </b-input-group>
  </b-form-group>
</template>

<script>

import { MonthPicker } from "vue-month-picker";

export default {
  name: "CalendarMonth",
  components: { MonthPicker },
  data: () => ({
    startMonth: '',
    endMonth: '',
    selectedStartMonth: null,
    selectedEndMonth: null,
    showStartMonthCalendarPopup: false,
    showEndMonthCalendarPopup: false,
  }),
  async created() {
    const now = new Date()
    const startMonth = this.formatStartDate(now);
    const endMonth = this.formatEndDate(now);
    await this.$store.dispatch("setStartMonth", startMonth);
    await this.$store.dispatch("setEndBasicMonth", startMonth);
    await this.$store.dispatch("setEndMonth", endMonth);
  },
  methods: {
    async monthStartOn() {
      setTimeout(async () => {
        if (this.selectedStartMonth === null || !this.selectedStartMonth.from) return false
        this.selectedStartMonth = this.selectedStartMonth?.from;
        this.selectedStartMonth = new Date(this.selectedStartMonth);
        const startMonth = this.formatStartDate(this.selectedStartMonth);
        this.startMonth = startMonth
        this.showStartMonthCalendarPopup = !this.showStartMonthCalendarPopup;
        this.showEndMonthCalendarPopup = false;
        await this.$store.dispatch("setStartMonth", startMonth);
      }, 100)
    },
    async monthEndOn() {
      setTimeout(async () => {
        if (this.selectedEndMonth === null || !this.selectedEndMonth.from) return false
        this.selectedEndMonth = this.selectedEndMonth?.from;
        this.selectedEndMonth = new Date(this.selectedEndMonth);
        this.endMonth = this.formatStartDate(this.selectedEndMonth);
        await this.$store.dispatch("setEndBasicMonth", this.endMonth);
        const endMonth = this.formatEndDate(this.selectedEndMonth);
        this.showEndMonthCalendarPopup = !this.showEndMonthCalendarPopup;
        this.showStartMonthCalendarPopup = false;
        await this.$store.dispatch("setEndMonth", endMonth);
      }, 100)
    },
    showStartMonthCalendar() {
      this.showStartMonthCalendarPopup = !this.showStartMonthCalendarPopup;
      this.showEndMonthCalendarPopup = false;
    },
    showEndMonthCalendar() {
      this.showEndMonthCalendarPopup = !this.showEndMonthCalendarPopup;
      this.showStartMonthCalendarPopup = false;
    },
    formatStartDate(Month) {
      // 날짜 형식을 원하는 형태로 변환하는 함수를 정의합니다.
      // 예: YYYY-MM-DD 형태로 변환
      const year = Month.getFullYear();
      const month = String(Month.getMonth() + 1).padStart(2, '0');
      return `${year}-${month}`;
    },
    formatEndDate(Month) {
      // 날짜 형식을 원하는 형태로 변환하는 함수를 정의합니다.
      // 예: YYYY-MM-DD 형태로 변환
      const reMonth = {
        '13': '01',
        '14': '02',
      }
      let year = Month.getFullYear();
      let month = String(Month.getMonth() + 2).padStart(2, '0');
      if (month === '13' || month === '14') {
        month = reMonth[month];
        year += 1
      }
      return `${year}-${month}`;
    },
  },
}
</script>

<style scoped>

</style>