<template>
  <div style="margin-bottom: 50px;">
    <b-tabs content-class="p-4">
      <b-tab title="충전 현황" active>
        <div class="d-flex"><h2 class="fw-900 m-0 mb-3">충전 현황</h2></div>
        <div class="justify-content-between align-items-center mb-3 d-flex flex-wrap" v-if="!parameter.includes('layout')">
          <b-row class="justify-content-end align-items-center">
            <!-- 달력 검색 -->
            <b-col>
              <Calendar></Calendar>
            </b-col>
            <!-- 검색어 -->
            <b-col>
              <b-form-group>
                <b-input-group>
                  <b-form-input
                          id="filter-input"
                          v-model="filter"
                          type="search"
                          placeholder="검색어"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-btn
                            @click="filter = null"
                            :disabled="!filter"
                            variant="outline-dark"
                            style="
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                  "
                    >초기화
                    </b-btn
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div>충전 완료 개수 : {{ charedAllCount.toLocaleString() }}개</div>
        <b-table
                head-variant="dark"
                bordered
                responsive
                hover
                selectable
                select-mode="single"
                @row-selected="onRowSelected"
                :sticky-header="true"
                :fields="fields"
                :items="chargeOnAir"
                class="dataTable salesTable chargingTable"
                :filter="filter"
                ref="selectableTable"
                show-empty
                emptyFilteredText="찾으시는 검색어와 일치하는 정보가 없습니다."
                emptyText="데이터 정보가 없습니다."
        >
          <template #head()="data">
            <div @click="isEllipsis = !isEllipsis" class="cursor-pointer">
              {{ data.label }}
            </div>
          </template>
          <template #cell()="data">
        <span>
          {{ data.value ? data.value : empty }}
        </span>
          </template>

          <!-- No -->
          <template #cell(index)="row">
            {{ row.index + 1 }}
          </template>
          <!-- 계약번호 -->
          <template #cell(ContractNumber)="row">
        <span>
          {{ dateFormat1(row.item.ContractNumber) }}
        </span>
          </template>
          <!-- 작업자 -->
          <template #cell(inflowPath)="row">
            <span v-if="row.item.inflowPath !== 'undefined'"> {{ row.item.inflowPath }} </span>
          </template>
          <!-- 실시간트래픽 -->
          <template #cell(trafficDataTodayCount)="row">
        <span>
          {{
            row.item.trafficData
                ? isNegative(row.item.trafficData.today_remain_count)
                : empty
          }}
        </span>
          </template>
          <!--     충전방식     -->
          <template #cell(chargeType)="row">
            {{ row.item.chargeType }}
          </template>
          <!--     플랫폼     -->
          <template #cell(platform)="row">
            <span v-if="row.item.platform === 'AF'">
              아프리카
            </span>
            <span v-else-if="row.item.platform === 'FX'">
              플렉스
            </span>
          </template>
          <!--    할인율      -->
          <template #cell(discountRate)="row">
            {{ row.item.discountRate * 100 }}%
          </template>
          <template #cell(chargedPrice)="row">
            {{ ((Number(row.item.complete_chared_count.replaceAll(',', '')) * 110) - (Number(row.item.complete_chared_count.replaceAll(',', '')) * 110 * row.item.discountRate)).toLocaleString() }}원
          </template>
          <!-- 확인 -->
          <template #cell(Approved)="row">
            <div class="text-center">
              <font-awesome-icon
                      v-if="row.item.Approved"
                      class="fa-xl text-success"
                      icon="fa-solid fa-circle-check"
              />
              <font-awesome-icon
                      v-else
                      style="color: #ced4da"
                      class="fa-xl"
                      icon="fa-solid fa-circle-xmark"
              />
            </div>
          </template>
          <!-- 결제금액 -->
          <template #cell(AmountOfPayment)="row">
        <span>
          {{
            row.item.AmountOfPayment
                ? numberToString(row.item.AmountOfPayment)
                : empty
          }}
        </span>
          </template>
          <!--   실패쿠폰     -->
          <template #cell(used_coupons)="row">
            <!-- <b-button
                    @click="failedCoupon(row.item)"
                    v-if="row.item.used_coupons.split(' ').join('') !== ''"
                    data-bs-toggle="modal" data-bs-target="#exampleModal"
            >실패쿠폰
            </b-button> -->
          </template>
          <!--   충전 현황     -->
          <template #cell(success)="row">
            <span :style="chargingStyle(row.item.success)">{{ row.item.success }}</span>
          </template>
        </b-table>
      </b-tab>
      <b-tab title="고객 관리" v-if="this.$route.path.includes('charging-status')">
        <div style="display: inline-flex">
          <h2 class="rw-900 m-0 mb-3">고객 관리</h2>
          <h5 style="margin: 12px 0 0 15px"> 재방문율 : {{ this.avg }}%</h5>
        </div>
        <div class="justify-content-between align-items-center mb-3 d-flex flex-wrap">
          <b-row class="justify-content-end align-items-center">
            <!-- 달력 검색 -->
            <b-col>
              <Calendar></Calendar>
            </b-col>
            <!-- 검색어 -->
            <b-col>
              <b-form-group v-slot="{ ariaDescribedby }">
                <b-input-group>
                  <b-form-input
                          id="filter-input"
                          v-model="filter"
                          :aria-describedby="ariaDescribedby"
                          type="search"
                          placeholder="검색어"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-btn
                            @click="filter = null"
                            :disabled="!filter"
                            variant="outline-dark"
                            style="
                      border-top-left-radius: 0;
                      border-bottom-left-radius: 0;
                    "
                    >초기화</b-btn
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <b-table
                head-variant="dark"
                bordered
                responsive
                hover
                selectable
                select-mode="single"
                @row-selected="onRowSelected"
                :sticky-header="true"
                :items="clientData"
                :fields="clientFields"
                class="dataTable salesTable chargingTable"
                :filter="filter"
                ref="selectableTable"
                show-empty
                emptyFilteredText="찾으시는 검색어와 일치하는 정보가 없습니다."
                emptyText="데이터 정보가 없습니다."
        >
          <template #head()="data">
            <div @click="isEllipsis = !isEllipsis" class="cursor-pointer">
              {{ data.label }}
            </div>
          </template>
          <template #cell()="data">
        <span>
          {{ data.value ? data.value : empty }}
        </span>
          </template>

          <!-- No -->
          <template #cell(index)="row">
            {{ row.index + 1 }}
          </template>
          <!--    고객 아이디      -->
          <template #cell(id)="row">
            {{ Object.keys(row.item)[0] }}
          </template>
          <!--     총 충전수량     -->
          <template #cell(AllCount)="row">
            <span>
              {{ (row.item[Object.keys(row.item)[0]].reduce((prev, next) => prev + parseInt(next.complete_chared_count.replaceAll(',','')), 0)).toLocaleString() }}
            </span>
          </template>

              <!-- 총 충전금액     -->
          <template #cell(AllPrice)="row">
            <span>
              {{ (row.item[Object.keys(row.item)[0]].reduce((prev, next) => prev + parseInt(next.complete_chared_count.replaceAll(',','')), 0) * 106.7).toLocaleString() }}
            </span>
          </template>

          <!-- 최초 이용 일시  -->
          <template #cell(createdAt)="row">
            <span>
              {{ row.item[Object.keys(row.item)[0]][0]?.createdAt }}
            </span>
          </template>

          <!-- 마지막 이용 일시  -->
          <template #cell(lastAt)="row">
            <span>
              {{ row.item[Object.keys(row.item)[0]][row.item[Object.keys(row.item)[0]].length - 1]?.createdAt }}
            </span>
          </template>

          <!-- 마지막 이용 기간  -->
          <template #cell(lastAtDate)="row">
            <span>
              {{ Math.floor((new Date().getTime() - new Date(row.item[Object.keys(row.item)[0]][row.item[Object.keys(row.item)[0]].length - 1]?.createdAt).getTime()) / (1000 * 60 * 60 * 24)) }}일
            </span>
          </template>

          <!--     충전횟수     -->
          <template #cell(count)="row">
            <b-button class="d-flex m-auto" @click="getInflowPath(row.item[Object.keys(row.item)])">
              {{ row.item[Object.keys(row.item)[0]].length }}
            </b-button>
          </template>
        </b-table>
      </b-tab>
    </b-tabs>
    <!-- Modal -->
    <b-modal class="modal fade"
             id="exampleModal"
             tabindex="-1"
             aria-labelledby="exampleModalLabel"
             aria-hidden="true"
             v-model="onModal"
             title="실패쿠폰">
      <div class="modal-dialog">
        <div v-for="item in errorCoupons" class="mb-3">
          <div><b>상품권 번호 :</b> {{item.serialNumber}}</div>
          <div>{{ item }}</div>
          <div><b>에러 내용 :</b> {{item.log}}</div>
        </div>
      </div>
    </b-modal>
    <b-modal class="modal fade"
             id="exampleModal"
             tabindex="-1"
             aria-labelledby="exampleModalLabel"
             aria-hidden="true"
             v-model="flowModal"
             title="유로입경로">
      <div class="modal-dialog">
        <div v-for="(key, value) in inflowDataList" class="mb-3">
          <div>{{value}} : {{key}}</div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
// import { MonthPicker } from "vue-month-picker";
import {mapGetters} from "vuex";
// import VDatePicker from "v-calendar/lib/components/date-picker.umd";
import Calendar from "@/components/Calendar.vue";

export default {
  name: "CurrentTable",
  components: { Calendar },
  computed: {
    ...mapGetters({
      avg: (['avg']),
      currentPlatForm: (['currentPlatForm']),
      charedAllCount: (['charedAllCount']),
      chargeOnAir: (['chargeOnAir']),
      getCoupons: (['getCoupons']),
      salesItemList: (['salesItems']),
      errorCoupons: (['errorCoupons']),
      clientData: (['clientData']),
    })
  },
  data: () => ({
    parameter: window.location.pathname,
    inflowDataList: [],
    selectedDate: '',
    flowModal: false,
    onModal: false,
    isEllipsis: false,
    // selectedDate: null,
    monthPickerVisible: false,
    filter: "",
    addTag: false,
    currentData: {},
    cachedData: {},
    paymentType: "AB",
    managerPricePredicted: null,
    managerPriceConfirm: null,
    salesItems: null,
    totalPricePredicted: null,
    totalPriceConfirm: null,
    clientFields: [
      {
        key: "index",
        label: "No",
        variant: "secondary",
        thClass: "",
      },
      {
        key: "id",
        label: "고객아이디",
        thClass: "table-secondary",
      },
      {
        key: "AllCount",
        label: "총 충전수량",
        thClass: "table-secondary",
      },
      {
        key: "AllPrice",
        label: "총 충전금액",
        thClass: "table-secondary",
      },
      {
        key: "count",
        label: "충전횟수",
        thClass: "table-secondary",
      },
      {
        key: "createdAt",
        label: "최초 이용 일시",
        thClass: "table-secondary",
      },
      {
        key: "lastAt",
        label: "마지막 이용 일시",
        thClass: "table-secondary",
      },
      {
        key: "lastAtDate",
        label: "마지막 이용 기간",
        // sortable: true,
        thClass: "table-secondary",
      },
    ],
    fields: [
      {
        key: "index",
        label: "No",
        // isRowHeader: true,
        // sortable: true,
        variant: "secondary",
        thClass: "",
        // filterByFormatted: true,
      },
      {
        key: "platform",
        label: "플랫폼",
        thClass: "table-secondary",
      },
      {
        key: "chargeType",
        label: "충전방법",
        thClass: "table-secondary",
      },
      {
        key: "afreecaId",
        label: "충전한 ID",
        thClass: "table-secondary",
      },
      {
        key: "platformId",
        label: "충전 ID",
        thClass: "table-secondary",
      },
      // {
      //   key: "used_coupons",
      //   label: "충전쿠폰",
      //   thClass: "table-secondary",
      // },
      {
        key: "chared_count",
        label: "충전예정개수",
        thClass: "table-secondary",
      },
      {
        key: "complete_chared_count",
        label: "충전완료개수",
        thClass: "table-secondary",
      },
      {
        key: "failed_count",
        label: "충전실패개수",
        thClass: "table-secondary",
      },
      {
        key: "discountRate",
        label: "할인율",
        thClass: "table-secondary",
      },
      {
        key: "chargedPrice",
        label: "충전금액",
        thClass: "table-secondary",
      },
      {
        key: "success",
        label: "충전현황",
        thClass: "table-secondary",
      },
      {
        key: "createdAt",
        label: "충전일자",
        thClass: "table-secondary",
      },
      {
        key: "worker_id",
        label: "작업자",
        thClass: "table-secondary",
      },
      {
          key: "inflowPath",
          label: "추천인",
          thClass: "table-secondary",
        },
      {
        key: "log",
        label: "내용",
        thClass: "table-secondary",
      },
    ],
    empty: "",
  }),
  async mounted() {
    await this.getChargeOnAir();
  },
  methods: {
    getInflowPath(row) {
      this.flowModal = true;
      const inflowData = {};
      row.forEach(item => {
        const path = item.inflowPath;
        if (path !== 'undefined') {
          if (!inflowData[path]) {
            inflowData[path] = 1;
          } else {
            inflowData[path] += 1;
          }
        }
      })
      this.inflowDataList = inflowData;
    },
    failedCoupon(row) {
      this.$store.dispatch('errorCoupons', {
        errorIds: row.used_coupons
      });
      this.onModal = !this.onModal;
    },
    chargingStyle(value) {
      if (value === '충전 완료') {
        return { color: 'blue' };
      } else if (value === '실패' || value === '쿠폰 조합 실패') {
        return { color: 'red' };
      } else {
        return { color: 'green' };
      }
    },
    async getChargeOnAir() {
      await this.$store.dispatch('getChargeOnAir' , { platForm: this.currentPlatForm});
      if (window.location.pathname.includes('layout')) {
        setTimeout(this.getChargeOnAir, 5000);
      } else {
        return false;
      }
    },
    onRowSelected(items) {
      this.addTag = false;
      items = items[0];
      this.currentData = { ...items };

      this.cachedData = Object.assign({}, this.currentData);
      this.cachedData.cardData = Object.assign({}, this.currentData.cardData);

      if (
        !this.currentData.cardData ||
        this.currentData.cardData.creditCardNumber === ""
      ) {
        this.paymentType = "cash";
      } else {
        this.paymentType = "card";
      }

      this.managerPricePredicted = 0;
      this.managerPriceConfirm = 0;
      const managerDataList = [];
      const managerPList = [];
      const managerCList = [];

      this.salesItemList.filter((el) => {
        if (el.manager === this.currentData.manager) {
          managerDataList.push(el);
        }
      });
      for (const i of managerDataList) {
        managerPList.push(i.AmountOfPayment);
        if (i.Approved === true) {
          managerCList.push(i.AmountOfPayment);
        }
      }

      this.managerPList = managerPList;
      this.managerCList = managerCList;

      this.managerPList.forEach((item) => {
        item = parseInt(item);
        if (isNaN(item) === false) {
          this.managerPricePredicted += parseInt(item);
        }
      });
      this.managerCList.forEach((item) => {
        item = parseInt(item);
        if (isNaN(item) === false) {
          this.managerPriceConfirm += parseInt(item);
        }
      });
    },
    // showMonthPicker() {
    //   this.monthPickerVisible = !this.monthPickerVisible;
    // },
    async showDate() {
      const data = await this.$axios.get(
        `${process.env.API_URL}/charge/chargeOnAir`
      );

      this.onMonthsalesData(data);
    },
    onMonthsalesData(data) {
      this.onList(data);
    },
    async getCurrentMonthsalesData() {
      const data = await this.$axios.get(
        `${process.env.API_URL}/charge/chargeOnAir`
      );
      const couponData = await this.$axios.get(
        `${process.env.API_URL}/charge/couponList`
      );
      this.onList(data, couponData);
    },
    async getSalesData() {
      const data = await this.$axios.get(
        `${process.env.API_URL}/charge/chargeOnAir`
      );


      const couponData = await this.$axios.get(
        `${process.env.API_URL}/charge/couponList`
      );

      this.onList(data, couponData);
    },
    onList(data, couponData) {
      const couponItems = couponData.data;
      const couponLists = [];
      // const datas = data.data;
      // const dataList = [];


      for (const i of couponItems) {
        couponLists.push(i);
      }

      // for (const i of datas) {
      //   dataList.push(i);
      // }

      this.couponItems = couponLists.reverse();
      this.couponItems.forEach((e) => {
        if (e.normal === 0 && e.used === 0) {
          if (this.couponList[e.price]) {
            this.couponList[e.price] += 1
          } else {
            this.couponList[e.price] = 1
          }
        }
        if (e.normal === 0) {
          e.normal = '정상';
        } else {
          e.normal = '비정상';
        }
        if (e.used === 0) {
          e.used = '미사용';
        } else {
          e.used = '사용';
        }
        e.price = Number(e.price).toLocaleString();
      });

      // this.salesItems = dataList.reverse();
      // this.salesItems.forEach((e) => {
      //   if (e.platform === 'AB') {
      //     e.platform = '아프리카';
      //   } else if (e.platform === 'CL') {
      //     e.platform = '컬쳐랜드';
      //   } else if (e.platform === 'BL') {
      //     e.platform = '도서문화상품권';
      //   } else if (e.platform === 'HM') {
      //     e.platform = '해피머니';
      //   }
      //   if (e.success === 0) {
      //     e.success = '결제 실패'
      //   } else if (e.success === 1) {
      //     e.success = '결제 완료'
      //   } else if (e.success === 2) {
      //     e.success = '결제 진행중'
      //   }
      //   e.chared_count = Number(e.chared_count).toLocaleString();
      //   e.complete_chared_count = Number(e.complete_chared_count).toLocaleString();
      //   e.failed_count = Number(e.failed_count).toLocaleString();
      // })

      // this.salesItems.forEach((el, index) => {
      //   this.$set(el, "index", index);
      // });

    },
  },
}
</script>

<style scoped>

</style>