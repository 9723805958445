<template>
    <b-row class="mb-4">
        <b-col>
            <div class="text-center h-100 bg-white border p-4" style="border-radius: 10px">
                <b-row class="justify-content-between align-items-center" v-if="currentPlatForm === 'AF'">
                  <b-col class="text-18 border-end">
                      <strong>별풍선</strong>
                      <p class="opacity-50 m-0">
                          <span>{{ Number(retainedAmount.starBalloon).toLocaleString() }}개</span>
                      </p>
                  </b-col>
                  <b-col class="text-18 border-end">
                      <strong>컬쳐랜드</strong>
                      <p class="opacity-50 m-0" v-for="(key, value, index) in retainedAmount?.chargeData?.CL" :key="index">
                          <span>{{ value }} : {{ Math.ceil(Number(key) / 110).toLocaleString() }}  개 </span>
                      </p>
                  </b-col>
                  <b-col class="text-18 border-end">
                      <strong>북앤라이프</strong>
                      <p class="opacity-50 m-0" v-for="(key, value, index) in retainedAmount?.chargeData?.BL" :key="index">
                          <span>{{ value }} : {{ Math.ceil(Number(key) / 110).toLocaleString() }}  개 </span>
                      </p>
                  </b-col>
                  <b-col class="text-18">
                      <strong>해피머니</strong>
                      <p class="opacity-50 m-0" v-for="(key, value, index) in retainedAmount?.chargeData?.HM" :key="index">
                          <span>{{ value }} : {{ Math.ceil(Number(key) / 110).toLocaleString() }}  개 </span>
                      </p>
                  </b-col>
              </b-row>
              <b-row class="justify-content-between align-items-center" v-else-if="currentPlatForm === 'FX'">
                    <b-col class="text-18 border-end">
                        <strong>렉스</strong>
                        <p class="opacity-50 m-0">
                            <span>{{ Number(retainedAmount.starBalloon).toLocaleString() }}개</span>
                        </p>
                    </b-col>
                </b-row>
            </div>
        </b-col>
    </b-row>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "RetainedAmount",
    data: () => ({
    }),
    computed: {
        ...mapGetters({
          currentPlatForm: (['currentPlatForm']),
          retainedAmount: (['retainedAmount']),
        }),
    },
    async mounted() {
        await this.$store.dispatch('getRetainedAmount', { platForm: this.currentPlatForm });
    },
    methods: {
    },
}
</script>

<style scoped>

</style>