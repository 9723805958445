<template>
  <b-container>
    <Header></Header>
    <Regist></Regist>
    <CouponTable></CouponTable>
  </b-container>
</template>

<script>
import {mapGetters} from "vuex";
import Header from "@/components/Nav/Header.vue";
import Regist from "@/components/Regist.vue";
import CouponTable from "@/components/CouponTable.vue";

export default {
  name: "CouponStatus",
  components: {Header, Regist, CouponTable},
  computed: {
    ...mapGetters({
      role: (['role']),
    })
  },
  async created() {
    this.currentPath = window.location.pathname;
    await this.$store.dispatch('getUsers', {userId: localStorage.getItem('workerId')});
    if (this.role !== '1') {
      this.$router.push('/layout');
    }
  },
}
</script>

<style scoped>

</style>