<template>
  <b-container style="margin-bottom: 50px;">
    <h2 class="fw-900 m-0 mb-3">쿠폰 현황</h2>
    <div class="justify-content-between align-items-center mb-3 d-flex flex-wrap">
      <b-row class="justify-content-end align-items-center">
        <!-- 달력 검색 -->
        <b-col>
          <Calendar></Calendar>
        </b-col>
        <!-- 검색어 -->
        <b-col>
          <b-form-group v-slot="{ ariaDescribedby }">
            <b-input-group>
              <b-form-input
                      id="filter-input"
                      v-model="filter"
                      :aria-describedby="ariaDescribedby"
                      type="search"
                      placeholder="검색어"
              ></b-form-input>
              <b-input-group-append>
                <b-btn
                        @click="filter = null"
                        :disabled="!filter"
                        variant="outline-dark"
                        style="
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                  "
                        class="p-2"
                >초기화</b-btn>
                <b-btn
                        style="margin-left: 18px"
                        class="p-2"
                        @click="deleteCheckData"
                >삭제</b-btn>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <reRegist :reItem="reItem" v-if="reRegist" @close="reRegist = false"/>
    <b-table
            head-variant="dark"
            bordered
            responsive
            hover
            selectable
            select-mode="single"
            @row-selected="onRowSelected"
            :sticky-header="true"
            :items="getCoupons"
            :fields="couponfields"
            class="dataTable salesTable couponTable"
            :filter="filter"
            ref="selectableTable"
            show-empty
            emptyFilteredText="찾으시는 검색어와 일치하는 정보가 없습니다."
            emptyText="데이터 정보가 없습니다."
    >
      <template #head()="data">
        <div @click="isEllipsis = !isEllipsis" class="cursor-pointer">
          {{ data.label }}
        </div>
      </template>
      <template #cell()="data">
        <span>
          {{ data.value ? data.value : empty }}
        </span>
      </template>

      <!-- No -->
      <template #cell(id)="row">
        {{ row.item.index }}
      </template>
      <!--  check   -->
      <template #cell(check)="row">
        <b-check v-model="row.checked" @input="onCheckboxChange(row)" class="text-center mt-2"></b-check>
      </template>
      <!--  check   -->
      <template #cell(count)="row">
        <span>
          {{ row.item.count.toLocaleString() }}
        </span>
      </template>
      <!-- 계약번호 -->
      <template #cell(ContractNumber)="row">
        <span>
          {{ dateFormat1(row.item.ContractNumber) }}
        </span>
      </template>
      <!-- 실시간트래픽 -->
      <template #cell(trafficDataTodayCount)="row">
        <span>
          {{
            row.item.trafficData
                ? isNegative(row.item.trafficData.today_remain_count)
                : empty
          }}
        </span>
      </template>
      <!-- 디자인 -->
      <template #cell(Design)="row">
        <div class="text-center">
          <font-awesome-icon
                  v-if="row.item.Design"
                  class="fa-xl text-success"
                  icon="fa-solid fa-circle-check"
          />
          <font-awesome-icon
                  v-else
                  style="color: #ced4da"
                  class="fa-xl"
                  icon="fa-solid fa-circle-xmark"
          />
        </div>
      </template>
      <!--  정상여부 재등록 버튼   -->
      <template #cell(normal)="row">
        <b-button v-if="row.item.normal === 1" @click="reRegistBtn(row.item)">재등록</b-button>
      </template>
      <!--  사용여부    -->
      <template #cell(used)="row">
        <span class="badge bg-primary d-block m-auto" v-if="row.item.used === 1">사용</span>
        <span class="badge d-block m-auto" v-else style="background-color: darkgray;: ">미사용</span>
      </template>
      <!-- 트래픽 -->
      <template #cell(Traffic)="row">
        <div class="text-center">
          <font-awesome-icon
                  v-if="row.item.Traffic"
                  class="fa-xl text-success"
                  icon="fa-solid fa-circle-check"
          />
          <font-awesome-icon
                  v-else
                  style="color: #ced4da"
                  class="fa-xl"
                  icon="fa-solid fa-circle-xmark"
          />
        </div>
      </template>
      <!-- 확인 -->
      <template #cell(Approved)="row">
        <div class="text-center">
          <font-awesome-icon
                  v-if="row.item.Approved"
                  class="fa-xl text-success"
                  icon="fa-solid fa-circle-check"
          />
          <font-awesome-icon
                  v-else
                  style="color: #ced4da"
                  class="fa-xl"
                  icon="fa-solid fa-circle-xmark"
          />
        </div>
      </template>
      <!-- 결제금액 -->
      <template #cell(AmountOfPayment)="row">
        <span>
          {{
            row.item.AmountOfPayment
                ? numberToString(row.item.AmountOfPayment)
                : empty
          }}
        </span>
      </template>
      <!-- 이메일 -->
      <template #cell(email)="row">
        <span>
          {{ row.item.blogId ? row.item.blogId + "@naver.com" : empty }}
        </span>
      </template>
      <!-- 주소 -->
      <!-- <template #head(address)="data">
        <div @click="isEllipsis = !isEllipsis" class="cursor-pointer">
          {{ data.label }}
        </div>
      </template> -->
      <template #cell(address)="row">
        <div>
          {{ row.item.address ? row.item.address : empty }}
        </div>
      </template>
      <!-- URL -->
      <!-- <template #head(url)="data">
        <div @click="isEllipsis = !isEllipsis" class="cursor-pointer">
          {{ data.label }}
        </div>
      </template> -->
      <template #cell(url)="row">
        <a
                v-if="row.item.blogId"
                :class="{ ellipsis: isEllipsis }"
                :href="'https://m.blog.naver.com/' + row.item.blogId"
                target="_blank"
        >
          {{ "https://blog.naver.com/" + row.item.blogId }}
        </a>
        <span v-else>{{ empty }}</span>
      </template>
      <!-- 결제일 -->
      <template #cell(payDate)="row">
        <span>
          {{ dateFormat2(row.item.ContractNumber) }}
        </span>
      </template>
      <!-- 시작일 -->
      <template #cell(trafficDataCreated)="row">
        <span>
          {{
            row.item.trafficData
                ? dateFormat2(row.item.trafficData.start_date)
                : empty
          }}
        </span>
      </template>
      <!-- 종료일 -->
      <template #cell(trafficDataExpiration)="row">
        <span>
          {{
            row.item.trafficData
                ? dateFormat2(row.item.trafficData.cexpiration_date)
                : empty
          }}
        </span>
      </template>
    </b-table>
  </b-container>
</template>

<script>
// import { MonthPicker } from "vue-month-picker";
import {mapGetters} from "vuex";
import Calendar from "@/components/Calendar.vue";
import reRegist from "@/components/Modal/reRegist.vue";


export default {
  name: "CouponTable",
  components: {
    Calendar,
    reRegist
  },
  computed: {
    ...mapGetters({
      currentPlatForm: (['currentPlatForm']),
      chargeOnAir: (['chargeOnAir']),
      getCoupons: (['getCoupons']),
      salesItemList: (['salesItems'])
    })
  },
  data: () => ({
    reRegist: false,
    reItem: {},
    checkDataList: [],
    getCheckTrueList: [],
    selectedDate: null,
    monthPickerVisible: false,
    couponfields: [
      {
        key: "id",
        label: "No",
        // isRowHeader: true,
        // sortable: true,
        variant: "secondary",
        thClass: "",
        // filterByFormatted: true,
      },
      {
        key: "check",
        label: "선택",
        thClass: "table-secondary",
      },
      {
        key: "serialNumber",
        label: "상품권번호",
        thClass: "table-secondary",
      },
      {
        key: "count",
        label: "가격",
        thClass: "table-secondary",
      },
      {
        key: "used",
        label: "사용여부",
        thClass: "table-secondary",
      },
      {
        key: "password",
        label: "비밀번호",
        thClass: "table-secondary",
      },
      {
        key: "normal",
        label: "정상여부",
        thClass: "table-secondary",
      },
      {
        key: "usedAt",
        label: "사용날짜",
        thClass: "table-secondary",
      },
      {
        key: "createdAt",
        label: "등록날짜",
        thClass: "table-secondary",
      },
      {
        key: "log",
        label: "오류상세",
        thClass: "table-secondary",
      },
    ],
    filter: "",
    isEllipsis: false,
    empty: "",
  }),
  async created() {
    await this.$store.dispatch('getCoupons', { platform: this.currentPlatForm });
  },
  methods: {
    onCheckboxChange(row) {
      if (row.checked) {
        this.checkDataList.push(row);
      } else {
        const index = this.checkDataList.indexOf(row);
        if (index !== -1) {
          this.checkDataList.splice(index, 1);
        }
      }
    },
    deleteCheckData() {
      // console.log('삭제');
    },
    reRegistBtn(row) {
      this.reItem = row;
      this.reRegist = !this.reRegist;
    },
    onRowSelected(items) {
      this.addTag = false;
      items = items[0];
      this.currentData = { ...items };

      this.cachedData = Object.assign({}, this.currentData);
      this.cachedData.cardData = Object.assign({}, this.currentData.cardData);

      if (
        !this.currentData.cardData ||
        this.currentData.cardData.creditCardNumber === ""
      ) {
        this.paymentType = "cash";
      } else {
        this.paymentType = "card";
      }

      this.managerPricePredicted = 0;
      this.managerPriceConfirm = 0;
      const managerDataList = [];
      const managerPList = [];
      const managerCList = [];

      this.chargeOnAir.filter((el) => {
        if (el.manager === this.currentData.manager) {
          managerDataList.push(el);
        }
      });
      for (const i of managerDataList) {
        managerPList.push(i.AmountOfPayment);
        if (i.Approved === true) {
          managerCList.push(i.AmountOfPayment);
        }
      }

      this.managerPList = managerPList;
      this.managerCList = managerCList;

      this.managerPList.forEach((item) => {
        item = parseInt(item);
        if (isNaN(item) === false) {
          this.managerPricePredicted += parseInt(item);
        }
      });
      this.managerCList.forEach((item) => {
        item = parseInt(item);
        if (isNaN(item) === false) {
          this.managerPriceConfirm += parseInt(item);
        }
      });
    },
    showMonthPicker() {
      this.monthPickerVisible = !this.monthPickerVisible;
    },
    async showDate() {
      const data = await this.$axios.get(
        `${process.env.API_URL}/charge/chargeOnAir`
      );

      this.onMonthsalesData(data);
    },
    onMonthsalesData(data) {
      this.onList(data);
    },
    clearDate() {
      this.date = null;
      this.selectedDate = null;
      this.monthPickerVisible = false;
    },
    async getCurrentMonthsalesData() {
      const data = await this.$axios.get(
        `${process.env.API_URL}/charge/chargeOnAir`
      );
      const couponData = await this.$axios.get(
        `${process.env.API_URL}/charge/couponList`
      );
      this.onList(data, couponData);
    },
    async getSalesData() {
      const data = await this.$axios.get(
        `${process.env.API_URL}/charge/chargeOnAir`
      );

      const couponData = await this.$axios.get(
        `${process.env.API_URL}/charge/couponList`
      );

      this.onList(data, couponData);
    },
    onList(data, couponData) {
      const couponItems = couponData.data;
      const couponLists = [];
      // const datas = data.data;
      // const dataList = [];


      for (const i of couponItems) {
        couponLists.push(i);
      }

      // for (const i of datas) {
      //   dataList.push(i);
      // }

      this.couponItems = couponLists.reverse();
      this.couponItems.forEach((e) => {
        if (e.normal === 0 && e.used === 0) {
          if (this.couponList[e.price]) {
            this.couponList[e.price] += 1
          } else {
            this.couponList[e.price] = 1
          }
        }
        if (e.normal === 0) {
          e.normal = '정상';
        } else {
          e.normal = '비정상';
        }
        if (e.used === 0) {
          e.used = '미사용';
        } else {
          e.used = '사용';
        }
        e.price = Number(e.price).toLocaleString();
      });

      // this.salesItems = dataList.reverse();
      // this.salesItems.forEach((e) => {
      //   if (e.platform === 'AB') {
      //     e.platform = '아프리카';
      //   } else if (e.platform === 'CL') {
      //     e.platform = '컬쳐랜드';
      //   } else if (e.platform === 'BL') {
      //     e.platform = '도서문화상품권';
      //   } else if (e.platform === 'HM') {
      //     e.platform = '해피머니';
      //   }
      //   if (e.success === 0) {
      //     e.success = '결제 실패'
      //   } else if (e.success === 1) {
      //     e.success = '결제 완료'
      //   } else if (e.success === 2) {
      //     e.success = '결제 진행중'failed_count
      //   }
      //   e.chared_count = Number(e.chared_count).toLocaleString();
      //   e.complete_chared_count = Number(e.complete_chared_count).toLocaleString();
      //   e.failed_count = Number(e.failed_count).toLocaleString();
      // })

      // this.salesItems.forEach((el, index) => {
      //   this.$set(el, "index", index);
      // });

    },
  },
}
</script>

<style scoped>

</style>