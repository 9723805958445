<template>
  <b-container>
    <Header></Header>
    <CurrentTable></CurrentTable>
  </b-container>
</template>

<script>
import {mapGetters} from "vuex";
import CurrentTable from "@/components/CurrentTable.vue";
import Header from "@/components/Nav/Header.vue";

export default {
  name: "ChargingStatus",
  components: {Header, CurrentTable},
  computed: {
    ...mapGetters({
      role: (['role']),
    })
  },
  async created() {
    this.currentPath = window.location.pathname;
    await this.$store.dispatch('getUsers', {userId: localStorage.getItem('workerId')});
    if (this.role !== '1') {
      this.$router.push('/layout');
    }
  },
}
</script>

<style scoped>

</style>