<template>
  <b-container class="mt-4">
    <b-tabs content-class="p-4" no-fade>
      <b-tab title="매입" active>
        <b-row class="mb-4">
        <b-col>
          <div class="text-center h-100 bg-white border p-4" style="border-radius: 10px">
            <b-row class="justify-content-between align-items-center" v-if="currentPlatForm === 'AF'">
              <b-col class="text-18 border-end">
                <strong>아프리카 교환권</strong>
                <p class="opacity-50 m-0">
                    <span>{{ chargeData.CP?.toLocaleString() }} 개</span>
                </p>
              </b-col>
              <b-col class="text-18 border-end">
                <strong>컬쳐랜드</strong>
                <p class="opacity-50 m-0" v-for="(key, value, index) in chargeData.CL" :key="index">
                    <span>{{ value }} : {{ Math.ceil(key / 110).toLocaleString() }}개</span>
                </p>
              </b-col>
              <b-col class="text-18 border-end">
                <strong>북앤라이프</strong>
                <p class="opacity-50 m-0" v-for="(key, value, index) in chargeData.BL" :key="index">
                    <span>{{ value }} : {{ Math.ceil(key / 110).toLocaleString() }}개</span>
                </p>
              </b-col>
              <b-col class="text-18">
                <strong>해피머니</strong>
                <p class="opacity-50 m-0" v-for="(key, value, index) in chargeData.HM" :key="index">
                    <span>{{ value }} : {{ Math.ceil(key / 110).toLocaleString() }}개</span>
                </p>
              </b-col>
            </b-row>
            <b-row class="justify-content-between align-items-center" v-else-if="currentPlatForm === 'FX'">
                <b-col class="text-18 border-end">
                  <strong>렉스</strong>
                  <p class="opacity-50 m-0">
                      <span>{{ chargeData.CP?.toLocaleString() }} 개</span>
                  </p>
                </b-col>
              </b-row>
            </div>
        </b-col>
    </b-row>
      <div class="justify-content-between align-items-center mb-3 d-flex" v-if="currentPlatForm === 'AF'">
        <b-table-simple small bordered fixed class="currentTable">
          <b-tbody>
            <b-tr>
              <b-th>상품권 종류</b-th>
              <b-td>
                <b-form-select v-model="paymentType"
                >
                  <b-form-select-option value="CL"
                  >컬쳐랜드
                  </b-form-select-option
                  >
                  <b-form-select-option value="BL"
                  >북앤라이프
                  </b-form-select-option
                  >
                  <b-form-select-option value="HM"
                  >해피머니
                  </b-form-select-option
                  >
                </b-form-select
                >
              </b-td>
              <b-th>충전 아이디</b-th>
              <b-td>
                <b-form-input
                        v-model="registId"
                        placeholder="충전 아이디"
                        @input="formatChargedPrice"
                ></b-form-input>
              </b-td>
              <b-th>충전 금액</b-th>
              <b-td>
                <b-form-input
                        v-model="registPrice"
                        placeholder="0"
                        @input="formatChargedPrice"
                ></b-form-input>
              </b-td>
              <b-th>충전 날짜</b-th>
              <b-td>
              <b-form-input
                v-model="endDate"
                class="form-control"
                type="text"
                placeholder="끝날짜"
                readonly
                @click="showEndDateCalendar"
              ></b-form-input>
              <VDatePicker
                        locale="ko"
                        title-position="left"
                        color="gray"
                        v-model="selectedEndDate"
                        @input="onEndDateSelected"
                        is-required
                        class="position-absolute"
                        style="z-index: 10; top: 29rem"
                        v-if="showEndDateCalendarPopup"
              ></VDatePicker>
            </b-td>
          </b-tr>
          <!-- 계좌이체 -->
        </b-tbody>
        </b-table-simple>
        <b-row class="">
          <b-col class="text-end">
            <b-btn variant="dark" class="m-2 " @click="charging()">충전</b-btn>
          </b-col>
        </b-row>
      </div>
        <b-table
                head-variant="dark"
                bordered
                responsive
                hover
                selectable
                select-mode="single"
                :sticky-header="true"
                :items="chargeData.Data"
                :fields="chargeFields"
                class="dataTable salesTable chargingTable"
                :filter="filter"
                ref="selectableTable"
                show-empty
                emptyFilteredText="찾으시는 검색어와 일치하는 정보가 없습니다."
                emptyText="데이터 정보가 없습니다."
                v-if="currentPlatForm === 'AF'"
        >
          <!-- No -->
          <template #cell(index)="row">
            {{ row.index + 1 }}
          </template>

          <!-- No -->
          <template #cell(chargeType)="row">
            <span>
              {{ platform[row.item.chargeType] }}
            </span>
          </template>

          <!-- No -->
          <template #cell(registPrice)="row">
            {{ row.item.registPrice?.toLocaleString() }}
          </template>

          <!-- No -->
          <template #cell(registDate)="row">
            {{ row.item.registDate.split(' ')[0] }}
          </template>
        </b-table>
      </b-tab>
      <b-tab title="매출">
        <div class="justify-content-between align-items-center mb-3">
          <div>
            <b-col>
              <div class="justify-content-between align-items-center mb-3 d-flex flex-wrap">
                <b-row class="justify-content-end align-items-center">
                  <b-col>
                    <b-table-simple small bordered fixed class="currentTable">
                      <b-tbody>
                        <b-tr>
                          <b-th>시작년도</b-th>
                          <b-td>
                            <b-form-select v-model="startingYear">
                              <b-form-select-option :value="item" v-for="item in yearList">{{item}}</b-form-select-option>
                            </b-form-select>
                          </b-td>
                          <b-th>끝년도</b-th>
                          <b-td>
                            <b-form-select v-model="endingYear">
                              <b-form-select-option :value="item" v-for="item in yearList">{{item}}</b-form-select-option>
                            </b-form-select>
                          </b-td>
                        </b-tr>
                        <!-- 계좌이체 -->
                      </b-tbody>
                    </b-table-simple>
                    <b-button style="width: 100%;" @click="getYearSales">조회</b-button>
                  </b-col>
                </b-row>
            </div>
            <div class="text-center h-100 bg-white border p-4" style="border-radius: 10px">
                <b-row class="justify-content-between align-items-center">
                    <b-col class="text-18">
                      <template v-if="startingYear === endingYear">
                        <strong>{{startingYear}}년 매출 총계</strong>
                      </template>
                      <template v-else>
                        <strong>{{startingYear}}년 ~ {{endingYear}}년 매출 총계</strong>
                      </template>

                      <p class="opacity-50 m-0">
                          <span>{{ yearSale?.toLocaleString() }} 개</span>
                      </p>
                    </b-col>
                  </b-row>
              </div>
            </b-col>
          </div>
        </div>
        <div class="justify-content-between align-items-center mb-3 flex-wrap">
          <b-row class="justify-content-end align-items-center">
            <!-- 달력 검색 -->
            <b-col>
              <CalendarMonth></CalendarMonth>
              <b-button style="width: 100%; margin-top: 10px;" @click="getMonthSales">조회</b-button>
            </b-col>
          </b-row>
        </div>
        <div class="justify-content-between align-items-center mb-3">
            <b-row class="mb-4">
              <b-col>
                <div class="text-center h-100 bg-white border p-4" style="border-radius: 10px">
                    <b-row class="justify-content-between align-items-center">
                      <b-col class="text-18">
                        <template v-if="startMonth === endBasicMonth">
                          <strong>{{ startMonth.split('-')[0] }}년 {{ startMonth.split('-')[1] }}월 매출</strong>
                          <p class="opacity-50 m-0">
                              <span>{{ MonthSale?.toLocaleString() }} 개</span>
                          </p>
                        </template>
                        <template v-else>
                          <strong>{{ startMonth.split('-')[0] }}년 {{ startMonth.split('-')[1] }}월 ~ {{ endBasicMonth.split('-')[0] }}년 {{ endBasicMonth.split('-')[1] }}월 매출</strong>
                          <p class="opacity-50 m-0">
                              <span>{{ MonthSale?.toLocaleString() }} 개</span>
                          </p>
                        </template>
                      </b-col>
                    </b-row>
                  </div>
              </b-col>
          </b-row>
        </div>
        <div class="justify-content-between align-items-center mb-3">
          <b-row class="mb-4">
            <b-col>
              <div class="text-center h-100 bg-white border p-4" style="border-radius: 10px">
                <b-row class="justify-content-between align-items-center" v-if="currentPlatForm === 'AF'">
                  <template v-if="startMonth === endBasicMonth">
                   <span class="mb-5 fs-4">{{ startMonth.split('-')[0] }}년 {{ startMonth.split('-')[1] }}월 매출</span>
                  </template>
                  <template v-else>
                    <span class="mb-5 fs-4">{{ startMonth.split('-')[0] }}년 {{ startMonth.split('-')[1] }}월 ~ {{ endBasicMonth.split('-')[0] }}년 {{ endBasicMonth.split('-')[1] }}월 매출</span>
                  </template>
                  <b-col class="text-18 border-end">
                    <strong>아프리카 교환권</strong>
                    <p class="opacity-50 m-0">
                        <span>{{ MonthSalePlatform?.AB?.toLocaleString() }}개</span>
                    </p>
                  </b-col>
                  <b-col class="text-18 border-end">
                    <strong>컬쳐랜드</strong>
                    <p class="opacity-50 m-0">
                        <span>{{ MonthSalePlatform?.CL?.toLocaleString() }} 개</span>
                    </p>
                  </b-col>
                  <b-col class="text-18 border-end">
                    <strong>북앤라이프</strong>
                    <p class="opacity-50 m-0">
                        <span>{{ MonthSalePlatform?.BL?.toLocaleString() }} 개</span>
                    </p>
                  </b-col>
                  <b-col class="text-18">
                    <strong>해피머니</strong>
                    <p class="opacity-50 m-0">
                        <span>{{ MonthSalePlatform?.HM?.toLocaleString() }} 개</span>
                    </p>
                  </b-col>
                </b-row>
                <b-row class="justify-content-between align-items-center" v-else-if="currentPlatForm === 'FX'">
                    <template v-if="startMonth === endBasicMonth">
                     <span class="mb-5 fs-4">{{ startMonth.split('-')[0] }}년 {{ startMonth.split('-')[1] }}월 매출</span>
                    </template>
                    <template v-else>
                      <span class="mb-5 fs-4">{{ startMonth.split('-')[0] }}년 {{ startMonth.split('-')[1] }}월 ~ {{ endBasicMonth.split('-')[0] }}년 {{ endBasicMonth.split('-')[1] }}월 매출</span>
                    </template>
                    <b-col class="text-18 border-end">
                      <strong>렉스 교환권</strong>
                      <p class="opacity-50 m-0">
                          <span>{{ MonthSalePlatform?.AB?.toLocaleString() }}개</span>
                      </p>
                    </b-col>
                  </b-row>
                </div>
            </b-col>
          </b-row>
        </div>
      </b-tab>
    </b-tabs>
  </b-container>
</template>

<script>
import {mapGetters} from "vuex";
import Swal from 'sweetalert2';
import CalendarMonth from "@/components/CalendarMonth.vue";
import VDatePicker from "v-calendar/lib/components/date-picker.umd";

export default {
  name: "StatisticsTable",
  components: { CalendarMonth, VDatePicker },
  computed: {
    ...mapGetters({
      currentPlatForm: (['currentPlatForm']),
      chargeData: (['chargeData']),
      getCoupons: (['getCoupons']),
      yearSale: (['yearSale']),
      MonthSale: (['MonthSale']),
      startMonth: (['startMonth']),
      endMonth: (['endMonth']),
      endBasicMonth: (['endBasicMonth']),
      MonthSalePlatform: (['MonthSalePlatform']),
    }),
  },
  data: () => ({
    startDate: '',
    endDate: '',
    selectedStartDate: null,
    selectedEndDate: null,
    showStartDateCalendarPopup: false,
    showEndDateCalendarPopup: false,
    filter: null,
    monthPickerVisibleStart: false,
    monthPickerVisibleEnd: false,
    chargeFields: [
      {
        key: "index",
        label: "No",
        variant: "secondary",
        thClass: "",
      },
      {
        key: "chargeType",
        label: "상품권 종류",
        thClass: "table-secondary",
      },
      {
        key: "registId",
        label: "충전 아이디",
        thClass: "table-secondary",
      },
      {
        key: "registPrice",
        label: "충전 금액",
        thClass: "table-secondary",
      },
      {
        key: "registDate",
        label: "충전 날짜",
        thClass: "table-secondary",
      },
    ],
    platform: {
      'CL': '컬쳐랜드',
      'BL': '북앤라이프',
      'HM': '해피머니',
    },
    paymentType: 'CL',
    registId: null,
    registPrice: null,
    registDate: '',
    startingYear: 0,
    endingYear: 0,
    startYear: 2000,
    yearList: [],
  }),
  async mounted() {
    this.endDate = this.formatDate(new Date());
  },
  async created() {
    await this.yearSet();
    await this.$store.dispatch('getCoupons', { platform: this.currentPlatForm });
    await this.$store.dispatch('getChargeOnAir');
    await this.$store.dispatch('getPurchaseCharging');
    await this.getYearSales();
    await this.getMonthSales();
  },
  methods: {
    formatDate(date) {
      // 날짜 형식을 원하는 형태로 변환하는 함수를 정의합니다.
      // 예: YYYY-MM-DD 형태로 변환
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    onEndDateSelected(date) {
      this.selectedEndDate = new Date(date);
      this.endDate = this.formatDate(this.selectedEndDate);
      this.showEndDateCalendarPopup = false;
    },
    showEndDateCalendar() {
      this.showEndDateCalendarPopup = !this.showEndDateCalendarPopup;
      this.showStartDateCalendarPopup = false;
    },
    async getMonthSales() {
      await this.$store.dispatch('getMonthSales', { startMonth: this.startMonth, endMonth: this.endMonth, platForm: this.currentPlatForm })
    },
    async showStartMonthCalendar() {
      this.monthPickerVisibleStart = !this.monthPickerVisibleStart;
      // console.log(this.monthPickerVisibleStart);
    },
    async showEndMonthCalendar() {
      this.monthPickerVisibleEnd = !this.monthPickerVisibleEnd;
      // console.log(this.monthPickerVisibleEnd);
    },
    async getYearSales() {
      await this.$store.dispatch('getYearSales', {
        startYear: this.startingYear,
        endYear: this.endingYear,
        platForm: this.currentPlatForm,
      });
    },
    async yearSet() {
      // 현재 날짜를 가져옵니다.
      const currentDate = new Date();

      // 시작 년도를 2000으로 설정합니다.

      // 현재 년도를 가져옵니다.
      const currentYear = currentDate.getFullYear();

      this.startingYear = currentYear;
      this.endingYear = currentYear;

      // 2000년부터 현재 년도까지의 년도 배열을 생성합니다.
      for (let year = this.startYear; year <= currentYear; year++) {
          this.yearList.push(year);
      }
      this.yearList.reverse();
    },
    async formatChargedPrice() {
      if (!this.registPrice) return; // 입력값이 없을 경우 빠른 리턴

      const priceValue = parseFloat(this.registPrice.replace(/,/g, ''));
      if (!isNaN(priceValue)) {
        this.registPrice = priceValue?.toLocaleString();
      }
    },
    async charging() {
      console.log(this.registPrice);
      if (this.endDate !== '') {
          await Swal.fire({
          title: '충전하기',
          text: `(${this.platform[this.paymentType]}) ${this.registId}에 충전 금액이 ${this.registPrice}원 맞습니까?`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: '네',
          cancelButtonText: '아니오',
        }).then(async (result) => {
            if (result.isConfirmed) {
              await this.$store.dispatch('purchaseCharging', {
                platform: this.currentPlatForm,
                registId: this.registId,
                paymentType: this.paymentType,
                registPrice: this.registPrice,
                registDate: this.endDate,
              });
              await this.$store.dispatch('getPurchaseCharging');
              await this.$store.dispatch('getChargeOnAir');
              await this.$store.dispatch('getPurchaseCharging');
              await this.$store.dispatch('getCoupons', { platform: this.currentPlatForm });
              this.registPrice = 0;
            }
          })
      } else {
        alert('충전 날짜를 입력해주세요');
      }
    }
  },
}
</script>

<style scoped>

</style>