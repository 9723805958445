<template>
  <b-container style="max-width: 1500px;">
    <Header></Header>
    <Charge></Charge>
    <CurrentTable></CurrentTable>
  </b-container>
</template>

<script>
import Header from "@/components/Nav/Header.vue";
import Charge from "@/components/Charge.vue";
import CurrentTable from "@/components/CurrentTable.vue";

export default {
  name: "Layout",
  components: { CurrentTable, Header, Charge },
}
</script>

<style scoped>

</style>