<template>
    <div>
      <Menu></Menu>
      <div style="max-width: 1850px;">
        <retained-amount></retained-amount>
      </div>
      <div v-if="pathName.includes('layout') && currentPlatForm === 'AF'">
        <b-row class="mb-4" style="border: solid 3px #dee2e6; padding: 10px; border-radius: 10px">
          <b-table-simple small bordered fixed class="currentTable">
            <b-tbody>
              <b-tr>
                <b-th>아프리카 ID</b-th>
                <b-td>
                  <b-input v-model="AFID"></b-input>
                </b-td>
                <b-th>아프리카 PW</b-th>
                <b-td>
                  <b-input v-model="AFPW"></b-input>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <b-button @click="getChargingRecord()" v-if="isCheck">조회하기</b-button>
          <b-button v-else-if="!isCheck" disable="true">{{ AFID }} 조회중..</b-button>
          <b-col>
              <div class="text-center h-100 bg-white border p-4" style="border-radius: 10px; margin-top: 5px;">
                  <b-row class="justify-content-between align-items-center">
                    <b-col class="text-18 border-end">
                        <strong>컬쳐랜드상품권</strong>
                        <p class="opacity-50 m-0" style="padding-top: 3px;">
                          <span>사용금액 : </span><span>{{ chargedRecord['컬쳐랜드상품권'] !== undefined ? chargedRecord['컬쳐랜드상품권'].toLocaleString() : 0 }}원</span>
                        </p>
                        <p class="opacity-50 m-0">
                          <span>남은금액 : </span><span>{{ chargedRecord['컬쳐랜드상품권'] !== undefined ? (3300000 - chargedRecord['컬쳐랜드상품권']).toLocaleString() : '3,300,000' }}원</span>
                        </p>
                    </b-col>
                    <b-col class="text-18 border-end">
                        <strong>북앤라이프</strong>
                        <p class="opacity-50 m-0"  style="padding-top: 3px;">
                          <span>사용금액 : </span><span>{{ chargedRecord['도서상품권'] !== undefined ? chargedRecord['도서상품권'].toLocaleString() : 0 }}원</span>
                        </p>
                        <p class="opacity-50 m-0">
                          <span>남은금액 : </span><span>{{ chargedRecord['도서상품권'] !== undefined ? (3300000 - chargedRecord['도서상품권']).toLocaleString() : '3,300,000' }}원</span>
                        </p>
                    </b-col>
                    <b-col class="text-18">
                        <strong>해피머니상품권</strong>
                        <p class="opacity-50 m-0"  style="padding-top: 3px;">
                          <span>사용금액 : </span><span>{{ chargedRecord['해피머니상품권'] !== undefined ? chargedRecord['해피머니상품권'].toLocaleString() : 0 }}원</span>
                        </p>
                        <p class="opacity-50 m-0">
                            <span>남은금액 : </span><span>{{ chargedRecord['해피머니상품권'] !== undefined ? (3300000 - chargedRecord['해피머니상품권']).toLocaleString() : '3,300,000' }}원</span>
                        </p>
                    </b-col>
                  </b-row>
              </div>
          </b-col>
        </b-row>
      </div>
    </div>
</template>

<script>
import RetainedAmount from "@/components/RetainedAmount.vue";
import Menu from "@/components/Nav/Menu.vue";
import {mapGetters} from "vuex";

export default {
  name: "Header",
  components: {Menu, RetainedAmount},
  data: () => ({
    pathName: window.location.pathname,
    AFID: null,
    AFPW: null,
    isCheck: true,
  }),
  async mounted() {
    await this.pageLoad();
  },
  computed: {
    ...mapGetters({
      currentPlatForm: (['currentPlatForm']),
      chargedRecord: (['chargedRecord']),
    }),
  },
  methods: {
    async getChargingRecord() {
      this.isCheck = !this.isCheck;
      this.$store.commit('setClientData', { Id: this.AFID, Pw: this.AFPW })
      await this.$store.dispatch('getChargingRecord', { AFID: this.AFID, AFPW: this.AFPW });
      this.isCheck = !this.isCheck;
    },
    async pageLoad() {
      // 저장된 토큰을 가져오는 함수
      let token = localStorage.getItem('token');
      if (!token) {
        await this.$router.push('/');
      }
      token = token.replaceAll('"', '');
      // eslint-disable-next-line no-unused-vars
      const data = await this.$axios.post(
        `${process.env.API_URL}/api/verify-token`, null, {
          headers: {
              Authorization: `Bearer ${token}`,
            }
          }
      );
      if (data.data.isValid === false) {
        await this.$router.push('/');
      }
    },
  }
};


</script>

<style></style>
