import Vue from "vue";
import VueRouter from "vue-router";

// Layouts
import Default from "@/layouts/Default.vue";

// Views(Pages)
// import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";
import Signup from "@/views/Signup.vue";
import Layout from "@/views/Layout.vue";
import ChargingStatus from "@/views/ChargingStatus.vue";
import CouponStatus from "@/views/CouponStatus.vue";
import Statistics from "@/views/Statistics.vue";
import RecommendStatus from "@/views/recommendStatus.vue";

import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Login,
    children: [
      {
        path: "/",
        name: "Login",
        component: Login,
        meta: { isLogin: true },
      },
      {
        path: "/signup",
        name: "Signup",
        component: Signup,
      },
    ],
  },
  {
    path: "/layout",
    name: "Layout",
    component: Layout,
  },
  {
    path: "/charging-status",
    name: "ChargingStatus",
    component: ChargingStatus,
  },
  {
    path: "/coupon-status",
    name: "CouponStatus",
    component: CouponStatus,
  },
  {
    path: "/recommend-status",
    name: "RecommendStatus",
    component: RecommendStatus,
  },
  {
    path: "/statistics",
    name: "Statistics",
    component: Statistics,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const isUser = localStorage.getItem("token");
  const isSuper = store.state.role;
  const roleSuper = to.meta.roleSuper;

  if (to.matched.some((record) => record.meta.isLogin)) {
    if (isUser) {
      // alert('로그인상태입니다');
      next("/layout");
      return;
    }
    next();
  }
  if (roleSuper) {
    if (!isSuper) {
      // console.log(roleSuper)
      // console.log(isSuper);
      alert("접근불가");
      next("/layout");
      return;
    }
    next();
  }
  next();
});

export default router;
